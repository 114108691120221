import {
  DOCUMENT_RETRIEVAL_DISTANCE_THRESHOLD,
  DOCUMENT_RETRIEVAL_K,
  DOCUMENTS_SOURCE,
} from "duck/graph/constants";
import { GraphStateType } from "duck/graph/state";
import getRespondToUserTool from "duck/graph/tools/getRespondToUserTool";
import { DuckGraphParams } from "duck/graph/types";
import { ToolNode } from "@langchain/langgraph/prebuilt";

import getClaimAnalyticsAgentNode, {
  getToolNode as getClaimAnalyticsAgentToolNode,
} from "./ClaimAnalyticsAgent/getNode";
import getRagAgentNode from "./RagAgent/getNode";
import getRejectClarifyNode from "./RejectClarifyAgent/getNode";
import getSignalEventAnalyticsAgentNode, {
  getToolNode as getSignalEventAnalyticsAgentToolNode,
} from "./SignalEventAnalyticsAgent/getNode";
import getSupervisorAgentNode from "./SupervisorAgent/getNode";
import { createDocumentRetrievalNode } from "./utils";

const getDocumentRetrievalNode = () =>
  createDocumentRetrievalNode(
    DOCUMENTS_SOURCE,
    DOCUMENT_RETRIEVAL_K,
    DOCUMENT_RETRIEVAL_DISTANCE_THRESHOLD
  );

const getRespondToUserToolNode = (
  params: DuckGraphParams
): ToolNode<GraphStateType> =>
  new ToolNode<GraphStateType>([
    getRespondToUserTool(params.uiHandlers.setAgentResponse),
  ]);

export {
  getClaimAnalyticsAgentNode,
  getClaimAnalyticsAgentToolNode,
  getRagAgentNode,
  getSupervisorAgentNode,
  getDocumentRetrievalNode,
  getRejectClarifyNode,
  getRespondToUserToolNode,
  getSignalEventAnalyticsAgentNode,
  getSignalEventAnalyticsAgentToolNode,
};
