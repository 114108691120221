import { PageHandler } from "duck/graph/PageHandler";
import { DynamicTool, tool } from "@langchain/core/tools";

import { ASSOCIATED_VEHICLES_TAB_KEY } from "pages/constants";

const getGoToAssociatedVehiclesTabTool = (
  pageHandler: PageHandler
): DynamicTool => {
  const goToAssociatedVehiclesTab = () => {
    console.debug("Navigating to Associated Vehicles tab");
    pageHandler.navigateToTab(ASSOCIATED_VEHICLES_TAB_KEY);
    return "queued navigation to Associated Vehicles tab";
  };

  return tool(goToAssociatedVehiclesTab, {
    name: "queueGoToAssociatedVehiclesTab",
    description:
      "Call this tool to queue a navigation to the Associated Vehicles tab",
  });
};

export default getGoToAssociatedVehiclesTabTool;
