import { createContext } from "react";

import { EntityAttribute } from "shared/api/api";
import { useFailureModeAttributes } from "shared/api/failureModes/hooks";

export const FailureModeAttributesContext = createContext<
  EntityAttribute[] | undefined
>(undefined);

const FailureModeContextWrapper = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const { data } = useFailureModeAttributes({});

  return (
    <FailureModeAttributesContext.Provider value={data}>
      {children}
    </FailureModeAttributesContext.Provider>
  );
};

export default FailureModeContextWrapper;
