import { DuckMessage } from "duck/context/DuckMessagesContextWrapper";

export const DUCK_VISIBILITY_KEY = "duck_visibility";
export const LANGCHAIN_THREAD_ID_KEY = "langchain_thread_id";

export const DUCK_POPPED_INITIAL_HEIGHT = 400;
export const DUCK_POPPED_INITIAL_WIDTH = 400;
export const DUCK_POPPED_INITIAL_X = 270;
export const DUCK_MESSAGES_KEY = "duck_messages";
export const DUCK_WELCOME_MESSAGE: DuckMessage = {
  author: "agent",
  message: "Quack. How may I help? Ask me anything and I’ll do my best!",
};
export const DUCK_ACTION_PERFORMED_MESSAGE: DuckMessage = {
  author: "agent",
  message: "_✅ Done_",
};
export const DUCK_ACTION_DISCARDED_MESSAGE: DuckMessage = {
  author: "agent",
  message: "_Action skipped_",
};
export const DUCK_DO_IT_FOR_ME = "Do it for me";
export const DUCK_GENERIC_ERROR_MESSAGE =
  "Sorry, I'm having trouble understanding your request. Please try again.";
