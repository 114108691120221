import AlertDefinitionAttributesContextWrapper from "./AlertDefinitionAttributesContextWrapper";
import ClaimAttributesContextWrapper from "./ClaimAttributesContextWrapper";
import CustomRecordAttributeContextWrapper from "./CustomRecordAttributesContextWrapper";
import CustomSignalEventDefinitionsAttributesContextWrapper from "./CustomSignalEventDefinitionsAttributesContextWrapper";
import DealerAttributesContextWrapper from "./DealerAttributesContextWrapper";
import EventRegistryContextWrapper from "./EventRegistryContext";
import FailureModeContextWrapper from "./FailureModeAttributesContextWrapper";
import InspectionAttributesContextWrapper from "./InspectionAttributesContextWrapper";
import InspectionItemAttributesContextWrapper from "./InspectionItemAttributesContextWrapper";
import IssuesAttributesContextWrapper from "./IssuesContextWrapper";
import RepairAttributesContextWrapper from "./RepairAttributesContextWrapper";
import RiskModelPredictionAttributesContextWrapper from "./RiskModelPredictionAttributesContextWrapper";
import SensorReadingAttributesContextWrapper from "./SensorReadingAttributesContextWrapper";
import ServicePlanAttributesContextWrapper from "./ServicePlanAttributesContextWrapper";
import ServiceRecommendationsAttributesContextWrapper from "./ServiceRecommendationAttributesContextWrapper";
import SignalEventOccurrenceAttributesContextWrapper from "./SignalEventOccurrenceAttributesContextWrapper";
import SuggestedIssuesContextWrapper from "./SuggestedIssueContextWrapper";
import VehicleAttributesContextWrapper from "./VehicleAttributesContextWrapper";
import VehicleECUsAttributeContextWrapper from "./VehicleECUsAttributesContextWrapper";

const ContextWrappers = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  return (
    <RepairAttributesContextWrapper>
      <InspectionAttributesContextWrapper>
        <ServiceRecommendationsAttributesContextWrapper>
          <ServicePlanAttributesContextWrapper>
            <VehicleECUsAttributeContextWrapper>
              <SensorReadingAttributesContextWrapper>
                <DealerAttributesContextWrapper>
                  <SignalEventOccurrenceAttributesContextWrapper>
                    <VehicleAttributesContextWrapper>
                      <ClaimAttributesContextWrapper>
                        <AlertDefinitionAttributesContextWrapper>
                          <SuggestedIssuesContextWrapper>
                            <IssuesAttributesContextWrapper>
                              <EventRegistryContextWrapper>
                                <CustomSignalEventDefinitionsAttributesContextWrapper>
                                  <FailureModeContextWrapper>
                                    <CustomRecordAttributeContextWrapper>
                                      <RiskModelPredictionAttributesContextWrapper>
                                        <InspectionItemAttributesContextWrapper>
                                          {children}
                                        </InspectionItemAttributesContextWrapper>
                                      </RiskModelPredictionAttributesContextWrapper>
                                    </CustomRecordAttributeContextWrapper>
                                  </FailureModeContextWrapper>
                                </CustomSignalEventDefinitionsAttributesContextWrapper>
                              </EventRegistryContextWrapper>
                            </IssuesAttributesContextWrapper>
                          </SuggestedIssuesContextWrapper>
                        </AlertDefinitionAttributesContextWrapper>
                      </ClaimAttributesContextWrapper>
                    </VehicleAttributesContextWrapper>
                  </SignalEventOccurrenceAttributesContextWrapper>
                </DealerAttributesContextWrapper>
              </SensorReadingAttributesContextWrapper>
            </VehicleECUsAttributeContextWrapper>
          </ServicePlanAttributesContextWrapper>
        </ServiceRecommendationsAttributesContextWrapper>
      </InspectionAttributesContextWrapper>
    </RepairAttributesContextWrapper>
  );
};

export default ContextWrappers;
