import { validateFilter } from "duck/graph/nodes/ClaimAnalyticsAgent/utils";
import { PageHandler } from "duck/graph/PageHandler";
import {
  NonEmptyStringArray,
  SignalEventAnalyticsAssociatedClaimsOptions,
} from "duck/graph/types";
import { z } from "zod";
import { StructuredTool, tool } from "@langchain/core/tools";

import { DEFAULT_GROUP_BY_ATTRIBUTE } from "pages/ClaimAnalytics/tabPages/TopContributors/TopContributors";
import { GROUP_BY_ATTRIBUTE_KEY } from "pages/constants";
import { ASSOCIATED_CLAIMS_TAB_KEY } from "pages/SignalEventsAnalytics/constants";
import {
  SE_ASSOCIATED_CLAIMS_KEY,
  SIGNAL_EVENTS_ASSOCIATED_CLAIMS_GROUP_BY_OPTIONS_KEY,
  SIGNAL_EVENTS_ASSOCIATED_CLAIMS_WINDOW_SIZE_OPTIONS_KEY,
  WINDOW_SIZE_KEY,
} from "pages/SignalEventsAnalytics/tabPages/AssociatedClaims/constants";

import {
  DEFAULT_WINDOW_SIZE,
  MAX_WINDOW_SIZE,
  MIN_WINDOW_SIZE,
} from "features/ui/Filters/FilterTypes/RelatesFilter/constants";
import { getPageKeyWithVersion, getQueryKeys } from "features/ui/Filters/utils";

const getGoToAssociatedClaimsTabTool = (
  pageHandler: PageHandler,
  groupByOptions: NonEmptyStringArray,
  selectedOptions: SignalEventAnalyticsAssociatedClaimsOptions
): StructuredTool => {
  const goToAssociatedClaimsTabSchema = z.object({
    groupByAttribute: z
      .enum(groupByOptions)
      .optional()
      .describe(
        `Claim attribute to group the results by. While Claim attributes can be referenced directly, Vehicle attributes must be prefixed with 'vehicle.'. Default value: '${DEFAULT_GROUP_BY_ATTRIBUTE}'.`
      ),
    claimFilter: z
      .string()
      .optional()
      .describe("Filter to apply to the associated claims."),
    windowSize: z
      .number()
      .optional()
      .describe(
        `Window size in days for claims occurring after base signal event. Range: ${MIN_WINDOW_SIZE}-${MAX_WINDOW_SIZE}. Default: ${DEFAULT_WINDOW_SIZE}.`
      ),
  });

  type GoToAssociatedClaimsTab = z.infer<typeof goToAssociatedClaimsTabSchema>;

  const goToAssociatedClaimsTab = async ({
    groupByAttribute,
    claimFilter: claimsFilter,
    windowSize,
  }: GoToAssociatedClaimsTab) => {
    console.debug(
      `[SEA]: Navigating to Associated Claims tab with groupByAttribute: ${groupByAttribute}, claimFilter: ${claimsFilter}, windowSize: ${windowSize}`
    );

    const {
      filterQueryString: selectedClaimsFilter,
      windowSize: selectedWindowSize,
      groupByAttribute: selectedGroupByAttribute,
    } = selectedOptions;

    const filterQueryString = claimsFilter ?? selectedClaimsFilter ?? "";
    await validateFilter({ claimsFilter: filterQueryString });

    const pageKeyWithVersion = getPageKeyWithVersion(SE_ASSOCIATED_CLAIMS_KEY);
    const { filtersKey } = getQueryKeys(pageKeyWithVersion);
    pageHandler.updateFilter(filterQueryString, filtersKey);

    const nonNullWindowSize =
      windowSize ?? selectedWindowSize ?? DEFAULT_WINDOW_SIZE;
    if (
      nonNullWindowSize < MIN_WINDOW_SIZE ||
      nonNullWindowSize > MAX_WINDOW_SIZE
    ) {
      throw new Error(
        `Invalid window size: ${nonNullWindowSize}. Must be between ${MIN_WINDOW_SIZE} and ${MAX_WINDOW_SIZE}.`
      );
    }

    const nonNullGroupByAttribute =
      groupByAttribute ??
      selectedGroupByAttribute ??
      DEFAULT_GROUP_BY_ATTRIBUTE;
    if (!groupByOptions.includes(nonNullGroupByAttribute)) {
      throw new Error(
        `Invalid groupByAttribute: ${nonNullGroupByAttribute}. Valid options: ${groupByOptions.join(", ")}`
      );
    }

    pageHandler.updateTabChartSettings(
      SE_ASSOCIATED_CLAIMS_KEY,
      ASSOCIATED_CLAIMS_TAB_KEY,
      {
        [SIGNAL_EVENTS_ASSOCIATED_CLAIMS_GROUP_BY_OPTIONS_KEY]: [
          { id: GROUP_BY_ATTRIBUTE_KEY, optionId: nonNullGroupByAttribute },
        ],
        [SIGNAL_EVENTS_ASSOCIATED_CLAIMS_WINDOW_SIZE_OPTIONS_KEY]: [
          { id: WINDOW_SIZE_KEY, optionId: nonNullWindowSize },
        ],
      }
    );

    return "queued navigation to Associated Claims tab";
  };

  return tool(goToAssociatedClaimsTab, {
    name: "queueGoToAssociatedClaimsTab",
    description:
      "Queues navigation to the Associated Claims tab, displaying claims grouped by {groupByAttribute} with filter {claimFilter} within {windowSize} days after the base signal event.",
    schema: goToAssociatedClaimsTabSchema,
  });
};

export default getGoToAssociatedClaimsTabTool;
