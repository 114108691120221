import { createContext } from "react";

import { EntityAttribute } from "shared/api/api";
import { useInspectionItemAttributes } from "shared/api/inspections/hooks";

export const InspectionItemAttributeContext = createContext<
  EntityAttribute[] | undefined
>(undefined);

const InspectionItemAttributesContextWrapper = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const { data } = useInspectionItemAttributes({});

  return (
    <InspectionItemAttributeContext.Provider value={data}>
      {children}
    </InspectionItemAttributeContext.Provider>
  );
};

export default InspectionItemAttributesContextWrapper;
