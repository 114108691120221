import { createContext } from "react";

import { EntityAttribute } from "shared/api/api";
import { useCustomRecordAttributes } from "shared/api/customRecords/hooks";

export const CustomRecordAttributeContext = createContext<
  EntityAttribute[] | undefined
>(undefined);

const CustomRecordAttributeContextWrapper = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const { data } = useCustomRecordAttributes({});

  return (
    <CustomRecordAttributeContext.Provider value={data}>
      {children}
    </CustomRecordAttributeContext.Provider>
  );
};

export default CustomRecordAttributeContextWrapper;
