import {
  LC_API_KEY,
  LC_ENDPOINT,
  LC_PROJECT_NAME,
  MODELSPEC,
} from "duck/graph/constants";
import { DuckGraphParams } from "duck/graph/types";
import { hasAllEnvVarsForViaDuck } from "duck/ui/utils";
import { Client } from "langsmith";
import { AIMessage, HumanMessage } from "@langchain/core/messages";
import { LangChainTracer } from "@langchain/core/tracers/tracer_langchain";

import * as config from "config/config";

import getGraph from "./getGraph";

// Create Langchain client and tracer
const client = new Client({ apiKey: LC_API_KEY, apiUrl: LC_ENDPOINT });
const tracer = new LangChainTracer({ client, projectName: LC_PROJECT_NAME });

// Main function to call agent
const callAgent = async (params: DuckGraphParams): Promise<void> => {
  if (!hasAllEnvVarsForViaDuck()) {
    console.warn(
      "Missing environment variables. Please set REACT_APP_LANGCHAIN_ENDPOINT, REACT_APP_LANGCHAIN_API_KEY, REACT_APP_LANGCHAIN_PROJECT, and REACT_APP_OPENAI_API_KEY."
    );
    throw new Error("Missing environment variables.");
  }

  const agentExecutor = await getGraph(params, false);

  const messages = params.messageHistory.map((message) =>
    message.author === "human"
      ? new HumanMessage(message.message, { name: "USER" })
      : new AIMessage(message.message, { name: "DUCK" })
  );
  messages.push(new HumanMessage(params.text, { name: "USER" }));

  if (params.screenshot) {
    const message = new HumanMessage({
      content: [
        {
          type: "text",
          text: "This is the screenshot of the current page. Refer to this for answering questions regarding charts or data and providing insights.",
        },
        {
          type: "image_url",
          image_url: {
            url: params.screenshot,
          },
        },
      ],
      name: "SYSTEM",
    });
    messages.push(message);
  }

  const { environment } = config.get();

  const graphRunnableConfig = {
    configurable: { thread_id: params.threadId },
    callbacks: [tracer],
    runName: "DUCK Graph",
    tags: [],
    metadata: {
      environment,
      tenant: params.tenant,
      user: params.user,
      nodeEnv: process.env.NODE_ENV,
      lsModelName: MODELSPEC.modelName,
      lsProvider: "openai",
      lsTemperature: MODELSPEC.temperature,
    },
  };

  const response = await agentExecutor.invoke(
    {
      userInput: params.text,
      messages,
      pageState: params.currentState,
    },
    graphRunnableConfig
  );

  console.log("Response:", response);
};

export default callAgent;
