// Icons
import { LDFlagSet } from "launchdarkly-js-client-sdk";
import {
  AiOutlineFileProtect as ClaimsIcon,
  AiOutlineGroup as CollectionsIcon,
  AiOutlineDashboard as DashboardIcon,
  AiOutlineFileSearch as DataExplorerIcon,
  AiFillTool as FailureModesIcon,
  AiOutlineExclamationCircle as IssuesIcon,
  AiOutlineAntDesign as PredictionsIcon,
  AiOutlineProfile as ServiceRecordsIcon,
  AiOutlineCar as VehiclesIcon,
} from "react-icons/ai";
import {
  BsBell as AlertDefinitionIcon,
  BsFillCalculatorFill as CalculatorIcon,
  BsChatLeftDots as ChatBotIcon,
  BsConeStriped as ConeIcon,
  BsPeopleFill as GroupsIcon,
  BsCardChecklist as ServicePlansIcon,
} from "react-icons/bs";
import {
  GiTruck as FleetsIcon,
  GiAutoRepair as RepairIcon,
} from "react-icons/gi";
import { generatePath, Location } from "react-router-dom";

import { getTenantServiceRecordName } from "shared/utils";

import { PAGE_TITLE as claimsAnalyticsTitle } from "pages/ClaimAnalytics/constants";
import { PAGE_TITLE as customSignalEventsTitle } from "pages/CustomSignalEvents/constants";
import { PAGE_TITLE as signalEventsAnalyticsTitle } from "pages/SignalEventsAnalytics/constants";

import { routes } from "services/routes";
import * as config from "config/config";

interface Subroute {
  to: string;
  text: string;
}

interface NavItem {
  icon: JSX.Element;
  text: JSX.Element | string;
  to: string;
  exact?: boolean;
  isExternal?: boolean;
  isActiveFunc?: (match: boolean, location: Location) => boolean;
  matchRoute?: string;
  // set to true if there are subroutes, but no matching item in nav menu
  hasUnlistedSubroutes?: boolean;
  subroutes?: Subroute[];
}

type NavItemOrNothing = NavItem | boolean | undefined;

export interface NavGroup {
  title: string;
  links: NavItem[];
}

export const NAV_ITEM_ICON_SIZE = 17;

export const getSideNavGroups = ({ flags }: LDFlagSet): NavGroup[] => {
  const { pages } = config.get();

  const rbacGroupsEnables = flags.rbac && flags.rbacGroups;
  const issuesEnabled = pages.issues?.enabled && flags.issues;
  const suggestedIssuesEnabled =
    pages.suggestedIssues?.enabled && flags.suggestedIssues;

  const qualityItems: NavItemOrNothing[] = [
    pages.signalEventsAnalytics?.enabled &&
      flags.signalEventsAnalytics && {
        icon: <ClaimsIcon size={NAV_ITEM_ICON_SIZE} />,
        text: signalEventsAnalyticsTitle,
        to: routes.signalEventAnalytics,
      },
    pages.claimAnalytics?.enabled &&
      flags.claimAnalytics && {
        icon: <ClaimsIcon size={NAV_ITEM_ICON_SIZE} />,
        text: claimsAnalyticsTitle,
        to: routes.claimAnalytics,
      },
    (issuesEnabled || suggestedIssuesEnabled) && {
      icon: <IssuesIcon size={NAV_ITEM_ICON_SIZE} />,
      text: "Issues",
      to: issuesEnabled ? routes.issues : routes.suggestedIssues,
      hasUnlistedSubroutes: true,
    },
  ];

  const quality = qualityItems.filter(Boolean) as NavItem[];

  const serviceItems: NavItemOrNothing[] = [
    pages.fleets?.enabled && {
      icon: <FleetsIcon size={NAV_ITEM_ICON_SIZE} />,
      text: "Fleets",
      to: routes.fleets,
      hasUnlistedSubroutes: true,
    },
    pages.vehicles?.enabled && {
      icon: <VehiclesIcon className="inline-block" size={NAV_ITEM_ICON_SIZE} />,
      text: "Vehicles",
      to: routes.vehicles,
      exact: true,
      hasUnlistedSubroutes: true,
    },
    pages.events?.enabled &&
      !pages.events?.hideMenuItem && {
        icon: <ServiceRecordsIcon size={NAV_ITEM_ICON_SIZE} />,
        text: getTenantServiceRecordName(),
        to: routes.serviceRecords,
      },
    pages.inspectionAnalytics &&
      flags.inspectionAnalytics && {
        icon: <ClaimsIcon size={NAV_ITEM_ICON_SIZE} />,
        text: "Inspection Analytics",
        to: routes.inspectionAnalytics,
        exact: true,
        hasUnlistedSubroutes: true,
      },
    pages.repairAnalytics &&
      flags.repairAnalytics && {
        icon: <RepairIcon size={NAV_ITEM_ICON_SIZE} />,
        text: "Repair Analytics",
        to: routes.repairAnalytics,
        exact: true,
        hasUnlistedSubroutes: true,
      },
    pages.servicePlans?.enabled &&
      flags.servicePlans && {
        icon: <ServicePlansIcon size={NAV_ITEM_ICON_SIZE} />,
        text: "Service Plans",
        to: routes.servicePlans,
        exact: true,
        hasUnlistedSubroutes: true,
      },
    pages.servicePlans?.enabled &&
      flags.servicePlans && {
        icon: <ServicePlansIcon size={NAV_ITEM_ICON_SIZE} />,
        text: "Service Recommendations",
        to: routes.serviceRecommendations,
        exact: true,
      },
    pages.failureModes?.enabled &&
      flags.legacyFailureModes && {
        icon: <FailureModesIcon size={NAV_ITEM_ICON_SIZE} />,
        text: "Failure Modes",
        to: routes.failureModes,
        hasUnlistedSubroutes: true,
      },
    pages.failureModes?.enabled &&
      flags.failureModesV1 && {
        icon: <ConeIcon size={NAV_ITEM_ICON_SIZE} />,
        text: "Failure Modes",
        to: routes.failureModesV1,
        hasUnlistedSubroutes: true,
      },
    pages.alertDefinitions?.enabled &&
      flags.alertDefinitions && {
        icon: <AlertDefinitionIcon size={NAV_ITEM_ICON_SIZE} />,
        text: "Alert Definitions",
        to: routes.alertDefinitions,
        exact: true,
        hasUnlistedSubroutes: true,
      },
  ];

  const service = serviceItems.filter(Boolean) as NavItem[];

  const dataHubItems: NavItemOrNothing[] = [
    pages.customSignalEvents?.enabled &&
      flags.customSignalEvents && {
        icon: <ClaimsIcon size={NAV_ITEM_ICON_SIZE} />,
        text: customSignalEventsTitle,
        to: routes.customSignalEvents,
      },
    pages.calculatedAttributes?.enabled &&
      flags.calculatedAttributes && {
        icon: <CalculatorIcon size={NAV_ITEM_ICON_SIZE} />,
        text: "Calculated Attributes",
        to: routes.calculatedAttributes,
        exact: true,
        hasUnlistedSubroutes: true,
      },
    pages.dataExplorer?.enabled && {
      icon: <DataExplorerIcon size={NAV_ITEM_ICON_SIZE} />,
      text: "Data Explorer",
      isExternal: true,
      to: pages.dataExplorer?.url,
    },
    rbacGroupsEnables && {
      icon: <GroupsIcon size={NAV_ITEM_ICON_SIZE} />,
      text: "Groups",
      to: routes.groups,
    },
    pages.failureModes?.enabled &&
      flags.legacyFailureModes && {
        icon: <PredictionsIcon size={NAV_ITEM_ICON_SIZE} />,
        text: (
          <span>
            Predictions
            <BetaBadge />
          </span>
        ),
        to: routes.predictions,
      },
    pages.collections?.enabled && {
      icon: <CollectionsIcon size={NAV_ITEM_ICON_SIZE} />,
      text: "Collections",
      to: routes.collections,
    },
    pages.chatBot?.enabled && {
      icon: <ChatBotIcon size={NAV_ITEM_ICON_SIZE} />,
      text: (
        <span>
          ChatBot
          <BetaBadge />
        </span>
      ),
      to: routes.chatBot,
    },
  ];

  const dataHub = dataHubItems.filter(Boolean) as NavItem[];
  const dashboardsItems: NavItemOrNothing[] = pages.dashboards?.enabled
    ? pages.dashboards.dashboards.map(({ id, name }) => {
        return {
          icon: <DashboardIcon size={NAV_ITEM_ICON_SIZE} />,
          text: <span>{name}</span>,
          to: generatePath(routes.dashboard, {
            id,
          }),
        };
      })
    : [];

  const DASHBOARDS = dashboardsItems.filter(Boolean) as NavItem[];

  const filterSideBarSections = ({ links }: NavGroup) => {
    return links.length > 0;
  };

  const groupItems: NavGroup[] = [
    {
      title: "Quality",
      links: quality,
    },
    {
      title: "Service",
      links: service,
    },
    {
      title: "Dashboards",
      links: DASHBOARDS,
    },
    {
      title: "Data Hub",
      links: dataHub,
    },
  ];

  return groupItems.filter(filterSideBarSections) as NavGroup[];
};

const BetaBadge = () => (
  <span className="text-gray-400 text-xs font-light">
    <br />
    BETA
  </span>
);
