import { SCREENCAPTURE_ELEMENT_ID } from "duck/graph/constants";
import {
  AvailableData,
  ByVehicleAgeChartOptionStrings,
  ClaimsChartOptionStrings,
  NonEmptyStringArray,
  PageState,
  SignalEventsChartOptionStrings,
  TopContributorsChartHierarchicalOptionStrings,
  VinViewTimelineChartOptionStrings,
} from "duck/graph/types";
import html2canvas from "html2canvas-pro";
import qs from "qs";

import { EntityAttribute } from "shared/api/api";
import { getSensors } from "shared/api/sensors/api";
import { NONE_EXPOSURE } from "shared/constants";
import { randomID } from "shared/utils";

import {
  VEHICLES_PAGE_KEY as CLAIM_ANALYTICS_VEHICLES_PAGE_KEY,
  CLAIMS_PAGE_KEY,
} from "pages/ClaimAnalytics/constants";
import { RELATES_FILTER_KEY } from "pages/ClaimAnalytics/tabPages/AssociatedSignalEvents";
import {
  CLAIM_ANALYTICS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY,
  CLAIM_ANALYTICS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY,
  DEFAULT_GROUP_BY_ATTRIBUTE as DEFAULT_CLAIM_ANALYTICS_TOP_CONTRIBUTORS_GROUP_BY_ATTRIBUTE,
} from "pages/ClaimAnalytics/tabPages/TopContributors/TopContributors";
import {
  BY_VEHICLES_AGE_TAB_KEY,
  GROUP_BY_ATTRIBUTE_KEY,
  TOP_CONTRIBUTORS_TAB_KEY,
} from "pages/constants";
import { mapByVehicleAgeExposureBuckets } from "pages/hooks";
import {
  ASSOCIATED_CLAIMS_TAB_KEY,
  VEHICLES_PAGE_KEY as SIGNAL_EVENTS_ANALYTICS_VEHICLES_PAGE_KEY,
  SIGNAL_EVENTS_PAGE_KEY,
  SIGNAL_EVENTS_TAB_KEY,
} from "pages/SignalEventsAnalytics/constants";
import {
  ASSOCIATED_CLAIMS_DEFAULT_GROUP_BY_ATTRIBUTE,
  SE_ASSOCIATED_CLAIMS_KEY,
  SIGNAL_EVENTS_ASSOCIATED_CLAIMS_GROUP_BY_OPTIONS_KEY,
  SIGNAL_EVENTS_ASSOCIATED_CLAIMS_WINDOW_SIZE_OPTIONS_KEY,
  WINDOW_SIZE_KEY,
} from "pages/SignalEventsAnalytics/tabPages/AssociatedClaims/constants";
import {
  getDefaultClaimFilters,
  getWindowSizeFromRelatesFilter,
} from "pages/SignalEventsAnalytics/tabPages/AssociatedClaims/utils";
import { SE_ASSOCIATED_SE_PAGE_KEY } from "pages/SignalEventsAnalytics/tabPages/AssociatedSignalEvents/constants";
import { SIGNAL_EVENTS_BY_VEHICLE_AGE_CHART_OPTIONS_KEY } from "pages/SignalEventsAnalytics/tabPages/ByVehicleAge/ByVehicleAge";
import { SIGNAL_EVENT_CHART_OPTIONS_KEY } from "pages/SignalEventsAnalytics/tabPages/SignalEvents/SignalEventsChart";
import {
  DEFAULT_GROUP_BY_ATTRIBUTE as DEFAULT_SIGNAL_EVENTS_TOP_CONTRIBUTORS_GROUP_BY_ATTRIBUTE,
  SIGNAL_EVENTS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY,
  SIGNAL_EVENTS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY,
} from "pages/SignalEventsAnalytics/tabPages/TopContributors/TopContributors";
import {
  VIN_VIEW_EVENTS_TIMELINE_TAB_KEY,
  VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_PREFIX,
} from "pages/VINView/constants";
import {
  CHART_ACTIONS,
  CHART_OPTIONS_KEY,
  VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_SE_FILTER_KEY_PREFIX,
  VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_SENSORS_TRIGGERS_KEY_PREFIX,
} from "pages/VINView/Events/constants";

import { ChartAction } from "features/ui/charts/Actions/types";
import {
  filterStateToFilterGroupState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import {
  DEFAULT_RELATES_FILTER,
  DEFAULT_WINDOW_SIZE,
} from "features/ui/Filters/FilterTypes/RelatesFilter/constants";
import {
  assertIsPageChartSettingsState,
  assertRelatesFilterWindowDirection,
  PageChartSettingsState,
  RelatesFilterState,
  RelatesFilterWindowDirection,
} from "features/ui/Filters/types";
import {
  getPageKeyWithVersion,
  getQueryKeys,
  getStateFromLocalStorage,
} from "features/ui/Filters/utils";
import { Option, SelectOption } from "features/ui/Select";

import * as config from "config/config";

import { DUCK_VISIBILITY_KEY, LANGCHAIN_THREAD_ID_KEY } from "./constants";

/**
 * If all of the Duck env vars exist, then we support Duck.
 * @returns True if the current environment supports the Duck UI; false if not.
 */
export const hasAllEnvVarsForViaDuck = (): boolean =>
  Boolean(
    process.env.REACT_APP_OPENAI_API_KEY &&
      process.env.REACT_APP_OPENAI_API_ORG &&
      process.env.REACT_APP_LANGCHAIN_ENDPOINT &&
      process.env.REACT_APP_LANGCHAIN_PROJECT &&
      process.env.REACT_APP_LANGCHAIN_API_KEY &&
      process.env.REACT_APP_LANGCHAIN_TRACING_V2 &&
      process.env.REACT_APP_LANGCHAIN_CALLBACKS_BACKGROUND
  );

type NonEmptyStringArrayAssertion = (
  arr: unknown
) => asserts arr is NonEmptyStringArray;

/**
 * @summary Asserts that the parameter is a non-empty array of strings.
 * @param arr The variable to check.
 * @throws An error if the parameter array is not an array, is empty,
 * or contains anything except strings.
 */
export const assertNonEmptyStringArray: NonEmptyStringArrayAssertion = (
  arr
) => {
  if (!Array.isArray(arr)) {
    throw new Error(`${arr} must be an array.`);
  }
  if (arr.length === 0) {
    throw new Error("Array must contain at least one element.");
  }
  if (arr.some((element) => typeof element !== "string")) {
    throw new Error("Array must contain only strings.");
  }
};

/**
 * @summary Converts an array of SelectOptions to a non-empty array of strings.
 * @param options The array of SelectOptions to convert
 * @returns A non-empty array of strings containing the SelectOptions' ids.
 * @throws An error if the parameter array is empty.
 */
export const toNonEmptyStringArray = (
  options: SelectOption<Option>[]
): NonEmptyStringArray => {
  const result = options.map((option) => String(option.id));
  assertNonEmptyStringArray(result);
  return result;
};

const extractFromActions = (
  actions: ChartAction<Option>[],
  actionId: string
): NonEmptyStringArray => {
  const action = actions.find((action) => action.id === actionId);
  if (!action || !action.options) {
    throw new Error(`Could not find action with options with id ${actionId}`);
  }

  return toNonEmptyStringArray(action.options);
};

export const getClaimsChartOptionStrings = (
  actions: ChartAction<Option>[]
): ClaimsChartOptionStrings => ({
  y: extractFromActions(actions, "y"),
});

export const getByVehicleAgeChartOptionStrings = (
  actions: ChartAction<Option>[]
): ByVehicleAgeChartOptionStrings => ({
  y: extractFromActions(actions, "y"),
  x: extractFromActions(actions, "x"),
  granularity: extractFromActions(actions, "granularity"),
  exposure: extractFromActions(actions, "exposure"),
});

export const getSignalEventsChartOptionStrings = (
  actions: ChartAction<Option>[]
): SignalEventsChartOptionStrings => ({
  y: extractFromActions(actions, "y"),
});

const getVinViewTimelineChartOptionStrings = (
  actions: ChartAction<Option>[]
): VinViewTimelineChartOptionStrings => ({
  legend: extractFromActions(actions, "legend"),
});

export const getVinViewAgentData = async (): Promise<
  AvailableData["vinView"]
> => {
  const sensorData = await getSensors({ limit: 1000 });
  const sensorOptions: NonEmptyStringArray =
    sensorData.data.length === 0
      ? ["No sensors available"]
      : (sensorData.data.map((sensor) => sensor.ID) as NonEmptyStringArray);

  return {
    timelineChartOptions: getVinViewTimelineChartOptionStrings(CHART_ACTIONS),
    sensorOptions,
  };
};

/**
 * It would be nice to use the useCustomLocalStorageState hook here, but this function
 * needs to be called when the user submits an utterance to the agent and it would be
 * awkward to call a hook at that time. Directly retrieving the value from localStorage
 * is simple enough.
 *
 * We have to say "T extends unknown" so that React knows that T is a generic type parameter
 * rather than a JSX element.
 */
const getFromLocalStorage = <T extends unknown>(
  key: string,
  defaultValue: T
): T => {
  try {
    const valueString = localStorage.getItem(getPageKeyWithVersion(key));
    const value: T = valueString ? JSON.parse(valueString) : defaultValue;
    return value;
  } catch (error) {
    return defaultValue;
  }
};

const getSelectedGroupByAttribute = (
  chartSettings: PageChartSettingsState | undefined,
  optionsKey: string,
  defaultGroupByAttribute: string
): string => {
  if (
    chartSettings &&
    chartSettings[TOP_CONTRIBUTORS_TAB_KEY] &&
    chartSettings[TOP_CONTRIBUTORS_TAB_KEY][optionsKey]
  ) {
    const foundGroupBySetting = chartSettings[TOP_CONTRIBUTORS_TAB_KEY][
      optionsKey
    ].find((option) => option.id === GROUP_BY_ATTRIBUTE_KEY);
    if (foundGroupBySetting) {
      return String(foundGroupBySetting.optionId);
    }
  }

  return defaultGroupByAttribute;
};

const getSelectedChartOptions = (
  chartSettings: PageChartSettingsState | undefined,
  tabKey: string,
  chartKey: string
): Record<string, string> => {
  if (
    chartSettings &&
    chartSettings[tabKey] &&
    chartSettings[tabKey][chartKey]
  ) {
    return chartSettings[tabKey][chartKey].reduce(
      (acc: Record<string, string>, option) => {
        acc[option.id] = String(option.optionId);
        return acc;
      },
      {}
    );
  }

  return {};
};

interface SignalEventOccurrencesData {
  signalEventOccurrencesFilterQueryString: string;
  signalEventOccurrencesWindowSize: number;
}

const getSignalEventOccurrencesData = (): SignalEventOccurrencesData => {
  const {
    pages: { signalEventsAnalytics },
  } = config.get();

  const defaultSignalEventFilters = filterStateToFilterGroupState(
    signalEventsAnalytics?.defaultSignalEventFilters
  );

  const defaultAppliedFilters = defaultSignalEventFilters
    ? {
        ...DEFAULT_RELATES_FILTER,
        filters: defaultSignalEventFilters,
      }
    : DEFAULT_RELATES_FILTER;

  const relatesFilter = getFromLocalStorage(
    RELATES_FILTER_KEY,
    defaultAppliedFilters
  );

  return {
    signalEventOccurrencesFilterQueryString: getFiltersQuery(
      relatesFilter?.filters
    ),
    signalEventOccurrencesWindowSize: +relatesFilter?.options?.windowSize,
  };
};

/**
 * The state of the claim analytics page.
 */
const getClaimAnalyticsPageState = (): PageState["claimAnalytics"] => {
  const {
    pages: { claimAnalytics },
  } = config.get();

  const claimsPageKeyWithVersion = getPageKeyWithVersion(CLAIMS_PAGE_KEY);
  const defaultClaimFilters = filterStateToFilterGroupState(
    claimAnalytics?.defaultClaimFilters
  );
  const claimFilterSortState = getStateFromLocalStorage(
    claimsPageKeyWithVersion,
    defaultClaimFilters
  );

  const vehiclesPageKeyWithVersion = getPageKeyWithVersion(
    CLAIM_ANALYTICS_VEHICLES_PAGE_KEY
  );
  const defaultVehicleFilters = filterStateToFilterGroupState(
    claimAnalytics?.defaultVehicleFilters
  );
  const vehiclesFilterSortState = getStateFromLocalStorage(
    vehiclesPageKeyWithVersion,
    defaultVehicleFilters
  );

  const signalEventOccurrencesData = getSignalEventOccurrencesData();

  return {
    claimsFilterQueryString: getFiltersQuery(claimFilterSortState.filters),
    vehiclesFilterQueryString: getFiltersQuery(vehiclesFilterSortState.filters),
    selectedGroupByAttribute: getSelectedGroupByAttribute(
      claimFilterSortState.chartSettings,
      CLAIM_ANALYTICS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY,
      DEFAULT_CLAIM_ANALYTICS_TOP_CONTRIBUTORS_GROUP_BY_ATTRIBUTE
    ),
    selectedTopContributorsChartOptions: getSelectedChartOptions(
      claimFilterSortState.chartSettings,
      TOP_CONTRIBUTORS_TAB_KEY,
      CLAIM_ANALYTICS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY
    ),
    signalEventOccurrencesFilterQueryString:
      signalEventOccurrencesData.signalEventOccurrencesFilterQueryString,
    signalEventOccurrencesWindowSize:
      signalEventOccurrencesData.signalEventOccurrencesWindowSize,
  };
};

const getSignalEventAssociatedClaimsGroupByAttribute = (
  chartSettings: PageChartSettingsState | undefined
): string => {
  const associatedClaimsGroupByOptions = getSelectedChartOptions(
    chartSettings,
    ASSOCIATED_CLAIMS_TAB_KEY,
    SIGNAL_EVENTS_ASSOCIATED_CLAIMS_GROUP_BY_OPTIONS_KEY
  );

  return (
    associatedClaimsGroupByOptions?.[GROUP_BY_ATTRIBUTE_KEY] ??
    ASSOCIATED_CLAIMS_DEFAULT_GROUP_BY_ATTRIBUTE
  );
};

const getSignalEventAssociatedClaimsWindowSize = (
  chartSettings: PageChartSettingsState | undefined
): number => {
  const associatedClaimsWindowSizeOptions = getSelectedChartOptions(
    chartSettings,
    ASSOCIATED_CLAIMS_TAB_KEY,
    SIGNAL_EVENTS_ASSOCIATED_CLAIMS_WINDOW_SIZE_OPTIONS_KEY
  );

  const windowSizeString = associatedClaimsWindowSizeOptions?.[WINDOW_SIZE_KEY];

  return windowSizeString ? parseInt(windowSizeString) : DEFAULT_WINDOW_SIZE;
};

interface WindowData {
  windowDirection: RelatesFilterWindowDirection;
  windowSize: number;
}

const getWindowDataFromRelatesFilter = (
  relatedSignalEventsFilter: RelatesFilterState | undefined
): WindowData => {
  const filterToUse = relatedSignalEventsFilter ?? DEFAULT_RELATES_FILTER;

  const windowSize = getWindowSizeFromRelatesFilter(filterToUse);
  const windowDirection = filterToUse.options.windowDirection;

  try {
    assertRelatesFilterWindowDirection(windowDirection);
    return {
      windowDirection,
      windowSize,
    };
  } catch (windowDirectionError) {
    console.warn(windowDirectionError);
    return {
      windowDirection: RelatesFilterWindowDirection.BEFORE,
      windowSize,
    };
  }
};

/**
 * The state of the signal events analytics page.
 */
const getSignalEventAnalyticsPageState =
  (): PageState["signalEventAnalytics"] => {
    const {
      pages: { signalEventsAnalytics },
    } = config.get();

    const signalEventsPageKeyWithVersion = getPageKeyWithVersion(
      SIGNAL_EVENTS_PAGE_KEY
    );
    const defaultSignalEventFilters = filterStateToFilterGroupState(
      signalEventsAnalytics?.defaultSignalEventFilters
    );
    const signalEventFilterSortState = getStateFromLocalStorage(
      signalEventsPageKeyWithVersion,
      defaultSignalEventFilters
    );

    const vehiclesPageKeyWithVersion = getPageKeyWithVersion(
      SIGNAL_EVENTS_ANALYTICS_VEHICLES_PAGE_KEY
    );
    const defaultVehicleFilters = filterStateToFilterGroupState(
      signalEventsAnalytics?.defaultVehicleFilters
    );
    const vehiclesFilterSortState = getStateFromLocalStorage(
      vehiclesPageKeyWithVersion,
      defaultVehicleFilters
    );

    const associatedClaimsFilterSortState = getStateFromLocalStorage(
      getPageKeyWithVersion(SE_ASSOCIATED_CLAIMS_KEY),
      getDefaultClaimFilters()
    );

    const associatedSignalEventsFilterSortState = getStateFromLocalStorage(
      getPageKeyWithVersion(SE_ASSOCIATED_SE_PAGE_KEY),
      getDefaultClaimFilters()
    );
    const { windowSize, windowDirection } = getWindowDataFromRelatesFilter(
      associatedSignalEventsFilterSortState.relatedSignalEventsFilter
    );

    return {
      signalEventsFilterQueryString: getFiltersQuery(
        signalEventFilterSortState.filters
      ),
      vehiclesFilterQueryString: getFiltersQuery(
        vehiclesFilterSortState.filters
      ),
      selectedSignalEventsChartOptions: getSelectedChartOptions(
        signalEventFilterSortState.chartSettings,
        SIGNAL_EVENTS_TAB_KEY,
        SIGNAL_EVENT_CHART_OPTIONS_KEY
      ),
      selectedByVehicleAgeChartOptions: getSelectedChartOptions(
        signalEventFilterSortState.chartSettings,
        BY_VEHICLES_AGE_TAB_KEY,
        SIGNAL_EVENTS_BY_VEHICLE_AGE_CHART_OPTIONS_KEY
      ),
      selectedTopContributorsChartOptions: getSelectedChartOptions(
        signalEventFilterSortState.chartSettings,
        TOP_CONTRIBUTORS_TAB_KEY,
        SIGNAL_EVENTS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY
      ),
      selectedTopContributorsGroupByAttribute: getSelectedGroupByAttribute(
        signalEventFilterSortState.chartSettings,
        SIGNAL_EVENTS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY,
        DEFAULT_SIGNAL_EVENTS_TOP_CONTRIBUTORS_GROUP_BY_ATTRIBUTE
      ),
      selectedAssociatedClaimsOptions: {
        filterQueryString: getFiltersQuery(
          associatedClaimsFilterSortState.filters
        ),
        windowSize: getSignalEventAssociatedClaimsWindowSize(
          associatedClaimsFilterSortState.chartSettings
        ),
        groupByAttribute: getSignalEventAssociatedClaimsGroupByAttribute(
          associatedClaimsFilterSortState.chartSettings
        ),
      },
      selectedAssociatedSignalEventsOptions: {
        filterQueryString: getFiltersQuery(
          associatedSignalEventsFilterSortState.filters
        ),
        windowSize,
        windowDirection,
      },
    };
  };

const getParamValue = (params: qs.ParsedQs, paramName: string): string =>
  params[paramName] ? String(params[paramName]) : "";

const getSelectedLegend = (chartSettingsString: string | undefined): string => {
  if (!chartSettingsString) return "";

  try {
    const chartSettings = JSON.parse(chartSettingsString);
    assertIsPageChartSettingsState(chartSettings);

    const selectedTimelineChartOptions = getSelectedChartOptions(
      chartSettings,
      VIN_VIEW_EVENTS_TIMELINE_TAB_KEY,
      CHART_OPTIONS_KEY
    );

    return selectedTimelineChartOptions.legend ?? "";
  } catch (error) {
    console.error(
      "Invalid chartSettings in query string",
      error,
      chartSettingsString
    );
    return "";
  }
};

/**
 * Attempt to extract the VIN from the current URL, if it is a VIN View page.
 * If we are on a VIN View page, the path should be of the form /vehicles/{VIN}.
 * If we are on any other page, we will not attempt to extract the VIN.
 *
 * @returns The VIN if we are on a VIN View page, otherwise null.
 */
const extractVinFromUrl = (): string | null => {
  const pathname = window?.location?.pathname;

  if (!pathname || !pathname.startsWith("/vehicles/")) {
    return null;
  }

  // There should be exactly 3 parts: ['', 'vehicles', '{VIN}']
  const parts = pathname.split("/");

  if (parts.length !== 3) {
    return null;
  }

  const vin = parts[2];

  if (!vin) {
    return null;
  }

  return vin;
};

const EMPTY_VIN_VIEW_PAGE_STATE: PageState["vinView"] = {
  selectedTimelineChartOptions: { legend: "" },
  selectedSensorsAndTriggers: "",
  selectedDateRange: "",
  selectedSignalEventFilters: "",
};

/**
 * The VIN view page opens in the context of a specific vehicle, so we need to
 * extract the page state from the query string instead of from local storage.
 * If the user is not already on the VIN view page, empty values will be used.
 */
const getVinViewPageState = (): PageState["vinView"] => {
  const vin = extractVinFromUrl();
  if (!vin) {
    return EMPTY_VIN_VIEW_PAGE_STATE;
  }

  const qsParams = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  const { filtersKey: dateFilterKey, chartSettingsKey } = getQueryKeys(
    getPageKeyWithVersion(VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_PREFIX)
  );
  const selectedDateRange = getParamValue(
    qsParams,
    `${dateFilterKey}${vin}_v2`
  );
  const selectedLegend = getSelectedLegend(
    getParamValue(qsParams, `${chartSettingsKey}${vin}_v2`)
  );

  const { filtersKey: sensorsAndTriggersFilterKey } = getQueryKeys(
    getPageKeyWithVersion(
      VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_SENSORS_TRIGGERS_KEY_PREFIX
    )
  );
  const selectedSensorsAndTriggers = getParamValue(
    qsParams,
    `${sensorsAndTriggersFilterKey}${vin}`
  );

  const { filtersKey: selectedSignalEventFiltersKey } = getQueryKeys(
    getPageKeyWithVersion(
      VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_SE_FILTER_KEY_PREFIX
    )
  );
  const selectedSignalEventFilters = getParamValue(
    qsParams,
    `${selectedSignalEventFiltersKey}${vin}`
  );

  return {
    selectedTimelineChartOptions: { legend: selectedLegend },
    selectedSensorsAndTriggers,
    selectedDateRange,
    selectedSignalEventFilters,
  };
};

/**
 * getPageState obtains the current page state so that it can be passed to the agent.
 * The data returned by this function is not static, and is updated when the user
 * navigates to a different tab or changes the filters. For this reason, we must obtain
 * it at the time that the agent is called.
 *
 * @returns The current page state.
 */
export const getPageState = (): PageState => {
  const { tab } = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  return {
    pathname: window.location.pathname,
    selectedTab: tab ? String(tab) : "",
    claimAnalytics: getClaimAnalyticsPageState(),
    signalEventAnalytics: getSignalEventAnalyticsPageState(),
    vinView: getVinViewPageState(),
  };
};

export const getInitialVisibility = (): boolean => {
  let initialVisibility = false;
  if (sessionStorage) {
    const visibilityFromStorage = sessionStorage.getItem(DUCK_VISIBILITY_KEY);
    if (visibilityFromStorage) {
      initialVisibility = true;
    }
  }

  return initialVisibility;
};

export const persistVisibility = (open: boolean) => {
  if (sessionStorage) {
    if (open) {
      sessionStorage.setItem(DUCK_VISIBILITY_KEY, "true");
    } else {
      sessionStorage.removeItem(DUCK_VISIBILITY_KEY);
    }
  }
};

export const getInitialThreadId = (): string => {
  let initialThreadId = randomID();
  if (sessionStorage) {
    const threadIdFromStorage = sessionStorage.getItem(LANGCHAIN_THREAD_ID_KEY);
    if (threadIdFromStorage) {
      initialThreadId = threadIdFromStorage;
    } else {
      sessionStorage.setItem(LANGCHAIN_THREAD_ID_KEY, initialThreadId);
    }
  }

  return initialThreadId;
};

export const getDuckHeight = (
  fillVerticalSpace: boolean,
  isDuckVisible: boolean
): string | undefined => {
  if (!isDuckVisible) {
    return undefined;
  }
  return fillVerticalSpace ? "100%" : "400px";
};

export const createExposureHierarchy = (
  topContributorsExposures: SelectOption<Option>[],
  attributes: EntityAttribute[] | undefined
): TopContributorsChartHierarchicalOptionStrings["exposure"] =>
  topContributorsExposures.reduce(
    (exposuresWithBuckets: Record<string, NonEmptyStringArray>, exposure) => {
      const exposureBuckets =
        exposure.id !== NONE_EXPOSURE
          ? mapByVehicleAgeExposureBuckets(attributes, String(exposure.id))
          : [{ id: "", value: "" }];

      if (exposureBuckets.length === 0) {
        exposureBuckets.push({ id: "", value: "" });
      }

      const bucketStrings = exposureBuckets.map((bucket) => String(bucket.id));
      assertNonEmptyStringArray(bucketStrings);

      exposuresWithBuckets[exposure.id] = bucketStrings;
      return exposuresWithBuckets;
    },
    {}
  );

/**
 * Capture a screenshot of a portion of the current page.
 * The captured element is inside src/features/layout/AppLayout.tsx.
 * It deliberately excludes the sidebar nav in order to reduce the size
 * of the screenshot. The html2canvas-pro library did not work
 * with the full page. It returned a completely empty canvas.
 *
 * Library selection for this was tricky. The html-to-image library
 * has a nice API but it generated a lot of errors in the console related
 * to CSS loading and it also had a bunch of black boxes in the image.
 * The original html2canvas library did not work at all. Not a huge
 * surprise since it hasn't been maintained for years.
 * This html2canvas-pro library is a fork of the original html2canvas
 * library that is maintained, and works well enough when we capture a
 * portion of the app. It did not work in attempts to capture the full page.
 *
 * @returns A base64 encoded data URL of an image of most of the app in
 * JPEG format.
 */
export const captureScreenshot = async (): Promise<string> => {
  const element =
    document.getElementById(SCREENCAPTURE_ELEMENT_ID) ?? document.body;

  const canvas = await html2canvas(element);
  // The quality value of 0.1 seems very low, but the image quality is still
  // sufficient for the use of the agent. It does not need to be pixel perfect.
  // The lower quality makes the resulting image much smaller.
  const screenshotData = canvas.toDataURL("image/jpeg", 0.1);

  return screenshotData;
};
