import loadPrompt from "duck/graph/nodes/loadPrompt";
import { InternalGetTools, promptNames } from "duck/graph/nodes/types";
import {
  createStrictToolCallingAgent,
  invokeAgentNode,
  NodeType,
} from "duck/graph/nodes/utils";
import { GraphStateType } from "duck/graph/state";
import { reportToSupervisorToolDef } from "duck/graph/tools/routingTools";
import { DuckGraphParams } from "duck/graph/types";
import { getLLM, NodeNames } from "duck/graph/utils";
import { StructuredTool } from "@langchain/core/tools";
import { ToolNode } from "@langchain/langgraph/prebuilt";

import getTools from "./tools/getTools";

let tools: StructuredTool[];

const internalGetTools: InternalGetTools = (
  params: DuckGraphParams
): StructuredTool[] => {
  if (!tools) {
    tools = getTools(params);
  }
  return tools;
};

const getNode = async (params: DuckGraphParams): Promise<NodeType> => {
  const prompt = await loadPrompt(promptNames.CLAIM_ANALYTICS_AGENT);
  const llm = getLLM();
  tools = internalGetTools(params);
  const agent = createStrictToolCallingAgent(
    llm,
    // note, the reason for this seperation is that the routing tools do no get executed in a tool node
    [...tools, reportToSupervisorToolDef],
    prompt
  );

  return invokeAgentNode(agent, undefined, NodeNames.CLAIM_ANALYTICS);
};

export const getToolNode = (
  params: DuckGraphParams
): ToolNode<GraphStateType> =>
  new ToolNode<GraphStateType>(internalGetTools(params), {
    handleToolErrors: true,
  });

export default getNode;
