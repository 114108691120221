import { useMemo } from "react";
import qs from "qs";
import { generatePath, Link } from "react-router-dom";

import {
  getFailureModeRiskModelPredictionsExport,
  RiskModelPrediction,
} from "shared/api/failureModes/api";
import {
  useFailureModeRiskModelPredictions,
  useFailureModeRiskModelPredictionsCount,
} from "shared/api/failureModes/hooks";
import { getSortFilter } from "shared/api/utils";
import { MAX_ROWS_DOWNLOAD_LIMIT } from "shared/constants";
import { FAILURE_MODE_RISK_MODEL_PREDICTIONS_GENERIC_FILTER } from "shared/filterDefinitions";
import { useRiskModelPredictionsSchema } from "shared/schemas/riskModelPredictionsSchema";
import { SortBy } from "shared/types";

import APIError from "features/ui/APIError";
import DownloadAction from "features/ui/DownloadAction";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { FilterSchemaItem } from "features/ui/Filters/types";
import Section from "features/ui/Section";
import { OnSortParams } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import TableCount from "features/ui/Table/TableCount";

import { routes } from "services/routes";

const RESULTS_PER_PAGE = 10;

const VIN_VIEW_HISTORY_TAB = 1;

const DEFAULT_SORT: SortBy = { riskLabel: "desc" };

const formatRow = (prediction: RiskModelPrediction) => {
  const search = { tab: VIN_VIEW_HISTORY_TAB };
  const pathname = generatePath(routes.vinView, {
    vin: encodeURIComponent(prediction.VIN),
  });
  return {
    ...prediction,
    VIN: (
      <Link
        to={{ pathname, search: qs.stringify(search) }}
        className="text-metabase-blue hover:underline"
      >
        {prediction.VIN}
      </Link>
    ),
  };
};

interface Props {
  failureModeID: string;
  vehicleFilters: FilterGroupState;
}

const FailureModePredictionsTable = ({
  failureModeID,
  vehicleFilters,
}: Props) => {
  const pageKey = `failure_mode_risk_model_predictions_table_${failureModeID}`;

  const { schema } = useRiskModelPredictionsSchema(
    (item: FilterSchemaItem) =>
      FAILURE_MODE_RISK_MODEL_PREDICTIONS_GENERIC_FILTER(failureModeID, item),
    ["failureModeID"],
    ["VIN"]
  );

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const {
    manageFilterChange,
    resetFilters,
    filters,
    initialized: filtersInitialized,
    sort,
    manageOnSortChange,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey,
    defaultSort: DEFAULT_SORT,
    disableUsingQuery: true,
  });

  const { data, isLoading, headers, error, ...paginationData } =
    useFailureModeRiskModelPredictions({
      id: failureModeID,
      limit: RESULTS_PER_PAGE,
      filter: getFiltersQuery(filters),
      sort: getSortFilter(sort),
      vehiclesFilter: getFiltersQuery(vehicleFilters),
    });

  const {
    data: countData,
    isLoading: countIsLoading,
    error: countError,
  } = useFailureModeRiskModelPredictionsCount({
    id: failureModeID,
    vehiclesFilter: getFiltersQuery(vehicleFilters),
    filter: getFiltersQuery(filters),
  });

  // re-format the data - but only when data changes
  const formattedData = useMemo(() => data?.map(formatRow), [data]);

  const downloadDisabled = !formattedData || formattedData.length === 0;

  return (
    <Section testId="failure-mode-events">
      <div className="flex space-x-3 items-center mb-3 mt-3 justify-between">
        <div>
          <FiltersOverview
            filters={filters}
            tableSchema={schema}
            onFiltersReset={resetFilters}
          />
        </div>
        <div className="flex space-x-3 items-center self-end">
          <TableCount
            extraClasses="ml-auto"
            count={countData?.count as number}
            entityName="result"
            isLoading={countIsLoading}
            error={!!countError}
          />
          <DownloadAction
            disabled={downloadDisabled}
            downloadFunc={getFailureModeRiskModelPredictionsExport}
            fileName="failure_mode_results"
            requestParams={{
              IDs: [failureModeID],
              vehiclesFilter: getFiltersQuery(vehicleFilters),
              filter: getFiltersQuery(filters),
              limit: MAX_ROWS_DOWNLOAD_LIMIT,
            }}
            count={countData?.count as number}
            entityName="result"
            filters={filters}
          />
        </div>
      </div>
      {!error && (
        <PaginatedTable
          isLoading={isLoading}
          loadingRows={RESULTS_PER_PAGE}
          data={formattedData}
          schema={schema}
          sortBy={sort}
          onSort={handleSorting}
          filtersInitialized={filtersInitialized}
          onFilterChange={manageFilterChange}
          filters={filters}
          pageKey={pageKey}
          {...paginationData}
        />
      )}
      {/* We get empty [] back when using filters and there's no results */}
      {(!formattedData || !formattedData.length) && !isLoading && !error && (
        <div className="py-4 text-gray-400 text-sm">No Results found.</div>
      )}
      {error && <APIError error={error} onBadRequest={resetFilterSortState} />}
    </Section>
  );
};

export default FailureModePredictionsTable;
