import { PageHandler } from "duck/graph/PageHandler";
import { DuckGraphParams } from "duck/graph/types";
import { z } from "zod";
import { DynamicStructuredTool, tool } from "@langchain/core/tools";

import {
  SIGNAL_EVENTS_PAGE_KEY,
  SIGNAL_EVENTS_TAB_KEY,
} from "pages/SignalEventsAnalytics/constants";
import { SIGNAL_EVENT_CHART_OPTIONS_KEY } from "pages/SignalEventsAnalytics/tabPages/SignalEvents/SignalEventsChart";

const getGoToSignalEventsTabTool = (
  handlers: PageHandler,
  signalEventsChartOptions: DuckGraphParams["availableData"]["signalEventAnalytics"]["signalEventsChartOptions"]
): DynamicStructuredTool => {
  const goToSignalEventsTabSchema = z.object({
    y: z
      .enum(signalEventsChartOptions.y)
      .optional()
      .describe(
        `The metric to be displayed on the y-axis of the chart. Default value: '${signalEventsChartOptions.y[0]}'.`
      ),
  });

  type GoToSignalEventsTab = z.infer<typeof goToSignalEventsTabSchema>;

  const goToSignalEventsTab = ({ y }: GoToSignalEventsTab): string => {
    console.debug("Navigating to Claims tab with yAxis: " + y);

    const nonNullY = y ?? signalEventsChartOptions.y[0];
    if (!signalEventsChartOptions.y.includes(nonNullY)) {
      throw new Error(
        `Invalid y option: ${nonNullY}. Valid options: ${signalEventsChartOptions.y.join(", ")}`
      );
    }

    handlers.updateTabChartSettings(
      SIGNAL_EVENTS_PAGE_KEY,
      SIGNAL_EVENTS_TAB_KEY,
      { [SIGNAL_EVENT_CHART_OPTIONS_KEY]: [{ id: "y", optionId: nonNullY }] }
    );
    return "queued navigation to Signal Events tab on the Signal Events Analytics page";
  };

  return tool(goToSignalEventsTab, {
    name: "queueGoToSignalEventsTab",
    description:
      "Call this tool to queue a navigation to the Signal Events tab on the Signal Events Analytics page",
    schema: goToSignalEventsTabSchema,
  });
};

export default getGoToSignalEventsTabTool;
