import { useFlags } from "launchdarkly-react-client-sdk";
import { Navigate, Route, Routes } from "react-router-dom";
import { LoginCallback } from "@okta/okta-react";

import AlertDefinitions from "pages/AlertDefinitions";
import CalculatedAttributes from "pages/CalculatedAttributes";
import CalculatedAttribute from "pages/CalculatedAttributes/CalculatedAttribute";
import ChatBot from "pages/ChatBot";
import ClaimAnalytics from "pages/ClaimAnalytics";
import SingleClaim from "pages/ClaimAnalytics/SingleClaim";
import Collections from "pages/Collections";
import CustomSignalEvents from "pages/CustomSignalEvents";
import CustomSignalEvent from "pages/CustomSignalEvents/CustomSignalEvent";
import CustomSignalEventForm from "pages/CustomSignalEvents/CustomSignalEventForm";
import Docs from "pages/Docs";
import FailureMode from "pages/FailureModes/FailureMode/FailureMode";
import FailureModes from "pages/FailureModes/FailureModes";
import Fleets from "pages/Fleets";
import FleetView from "pages/FleetView";
import Groups from "pages/Groups";
import Group from "pages/Groups/Group";
import InspectionAnalytics from "pages/InspectionAnalytics";
import SingleInspection from "pages/InspectionAnalytics/SingleInspection";
import Issues from "pages/Issues";
import Issue from "pages/Issues/Issue";
import SuggestedIssue from "pages/Issues/SuggestedIssues/SuggestedIssue";
import Dashboard from "pages/MetabaseDashboardPage/MetabaseDashboardPage";
import NotFound from "pages/NotFound";
import RepairAnalytics from "pages/RepairAnalytics";
import SingleRepair from "pages/RepairAnalytics/SingleRepair/SingleRepair";
import ServicePlans from "pages/ServicePlans";
import ServicePlan from "pages/ServicePlans/Form/ServicePlan";
import ServiceRecommendations from "pages/ServiceRecommendations";
import ServiceRecords from "pages/ServiceRecords";
import SingleCustomRecord from "pages/ServiceRecords/SingleCustomRecord";
import Settings from "pages/Settings";
import SignalEventsAnalytics from "pages/SignalEventsAnalytics";
import SingleSignalEventOccurrence from "pages/SignalEventsAnalytics/SingleSignalEventOccurrence";
import FailureModeV0 from "pages/V0_FailureMode";
import FailureModesV0 from "pages/V0_FailureModes";
import PredictionsV0 from "pages/V0_Predictions";
import Vehicles from "pages/Vehicles";
import { VEHICLES_PAGE_KEY } from "pages/Vehicles/constants";
import Version from "pages/Version";
import VINView from "pages/VINView";

import LoginLoading from "features/auth/Login/LoginLoading";
import SecureComponent from "features/auth/Login/SecureComponent";

import { redirects, routes } from "services/routes";
import * as config from "config/config";

import AlertDefinition from "./pages/AlertDefinitions/Form/AlertDefinition";
import SuggestedIssueLatestRun from "./pages/Issues/SuggestedIssues/SuggestedIssueLatestRun";

const AppRoutes = () => {
  const {
    settingsPage,
    servicePlans,
    alertDefinitions,
    claimAnalytics,
    repairAnalytics,
    inspectionAnalytics,
    signalEventsAnalytics,
    issues,
    suggestedIssues,
    rbac,
    rbacGroups,
    calculatedAttributes,
    customSignalEvents,
    failureModesV1,
    legacyFailureModes,
  } = useFlags();
  const { pages } = config.get();
  const dashboards =
    (pages.dashboards?.enabled && pages.dashboards?.dashboards) || [];

  const rootPage = pages.rootPage?.route
    ? pages.rootPage.route
    : routes.vehicles;

  const issuesEnabled = pages.issues?.enabled && issues;
  const suggestedIssuesEnabled =
    pages.suggestedIssues?.enabled && suggestedIssues;

  /**
   * Ideally we start "lifting" routes up to the data router:
   * - https://reactrouter.com/en/main/upgrading/v6-data#start-lifting-routes-and-leveraging-the-data-apis
   */
  return (
    <Routes>
      {Object.entries(redirects).map(([from, to]) => (
        <Route path={from} key={to} element={<Navigate to={to} />} />
      ))}
      <Route
        path={routes.loginCallback}
        element={<LoginCallback loadingElement={<LoginLoading />} />}
      />
      <Route
        path={routes.root}
        element={
          <SecureComponent>
            <Navigate to={rootPage} />
          </SecureComponent>
        }
      />
      <Route
        path={routes.docs}
        element={
          <SecureComponent wrapperClasses="pl-4">
            <Docs />
          </SecureComponent>
        }
      />
      {pages.failureModes?.enabled && legacyFailureModes && (
        <Route
          path={routes.predictions}
          element={
            <SecureComponent>
              <PredictionsV0 title="Predictions" />
            </SecureComponent>
          }
        />
      )}
      {pages.dashboards?.enabled && dashboards.length > 0 && (
        <Route
          path={routes.dashboard}
          element={
            <SecureComponent wrapperClasses="pt-6">
              <Dashboard />
            </SecureComponent>
          }
        />
      )}
      {pages.vehicles?.enabled && (
        <>
          <Route
            path={routes.vehicles}
            element={
              <SecureComponent>
                <Vehicles pageKey={VEHICLES_PAGE_KEY} />
              </SecureComponent>
            }
          />
          <Route
            path={routes.vinView}
            element={
              <SecureComponent>
                <VINView />
              </SecureComponent>
            }
          />
        </>
      )}
      {pages.fleets?.enabled && (
        <>
          <Route
            path={routes.fleets}
            element={
              <SecureComponent>
                <Fleets />
              </SecureComponent>
            }
          />
          <Route
            path={routes.fleetView}
            element={
              <SecureComponent>
                <FleetView />
              </SecureComponent>
            }
          />
        </>
      )}
      {servicePlans && pages.servicePlans?.enabled && (
        <>
          <Route
            path={routes.servicePlans}
            element={
              <SecureComponent>
                <ServicePlans />
              </SecureComponent>
            }
          />
          <Route
            path={routes.newServicePlan}
            element={
              <SecureComponent>
                <ServicePlan />
              </SecureComponent>
            }
          />
          <Route
            path={routes.servicePlan}
            element={
              <SecureComponent>
                <ServicePlan />
              </SecureComponent>
            }
          />
          <Route
            path={routes.serviceRecommendations}
            element={
              <SecureComponent>
                <ServiceRecommendations />
              </SecureComponent>
            }
          />
        </>
      )}
      {alertDefinitions && pages.alertDefinitions?.enabled && (
        <>
          <Route
            path={routes.alertDefinitions}
            element={
              <SecureComponent>
                <AlertDefinitions />
              </SecureComponent>
            }
          />
          <Route
            path={routes.alertDefinition}
            element={
              <SecureComponent>
                <AlertDefinition />
              </SecureComponent>
            }
          />
          <Route
            path={routes.newAlertDefinition}
            element={
              <SecureComponent>
                <AlertDefinition />
              </SecureComponent>
            }
          />
        </>
      )}
      {calculatedAttributes && pages.calculatedAttributes?.enabled && (
        <>
          <Route
            path={routes.calculatedAttributes}
            element={
              <SecureComponent>
                <CalculatedAttributes />
              </SecureComponent>
            }
          />
          <Route
            path={routes.newCalculatedAttribute}
            element={
              <SecureComponent>
                <CalculatedAttribute />
              </SecureComponent>
            }
          />
          <Route
            path={routes.calculatedAttribute}
            element={
              <SecureComponent>
                <CalculatedAttribute />
              </SecureComponent>
            }
          />
        </>
      )}
      {pages.collections?.enabled && (
        <Route
          path={routes.collections}
          element={
            <SecureComponent>
              <Collections />
            </SecureComponent>
          }
        />
      )}
      {settingsPage && (
        <Route
          path={routes.settings}
          element={
            <SecureComponent>
              <Settings />
            </SecureComponent>
          }
        />
      )}
      {pages.failureModes?.enabled && legacyFailureModes && (
        <>
          <Route
            path={routes.failureModes}
            element={
              <SecureComponent>
                <FailureModesV0 />
              </SecureComponent>
            }
          />
          <Route
            path={routes.failureMode}
            element={
              <SecureComponent>
                <FailureModeV0 />
              </SecureComponent>
            }
          />
        </>
      )}
      {pages.failureModes?.enabled && failureModesV1 && (
        <>
          <Route
            path={routes.failureModesV1}
            element={
              <SecureComponent>
                <FailureModes />
              </SecureComponent>
            }
          />
          <Route
            path={routes.failureModeV1}
            element={
              <SecureComponent>
                <FailureMode />
              </SecureComponent>
            }
          />
        </>
      )}
      {pages.inspectionAnalytics?.enabled && inspectionAnalytics && (
        <>
          <Route
            path={routes.inspectionAnalytics}
            element={
              <SecureComponent>
                <InspectionAnalytics />
              </SecureComponent>
            }
          />
          <Route
            path={routes.inspectionAnalyticsDetails}
            element={
              <SecureComponent>
                <SingleInspection />
              </SecureComponent>
            }
          />
        </>
      )}
      <Route
        path={routes.customRecordDetails}
        element={
          <SecureComponent>
            <SingleCustomRecord />
          </SecureComponent>
        }
      />
      {pages.repairAnalytics?.enabled && repairAnalytics && (
        <>
          <Route
            path={routes.repairAnalytics}
            element={
              <SecureComponent>
                <RepairAnalytics />
              </SecureComponent>
            }
          />
          <Route
            path={routes.repairAnalyticsDetails}
            element={
              <SecureComponent>
                <SingleRepair />
              </SecureComponent>
            }
          />
        </>
      )}
      {pages.claimAnalytics?.enabled && claimAnalytics && (
        <>
          <Route
            path={routes.claimAnalytics}
            element={
              <SecureComponent>
                <ClaimAnalytics />
              </SecureComponent>
            }
          />
          <Route
            path={routes.claimAnalyticsDetails}
            element={
              <SecureComponent>
                <SingleClaim />
              </SecureComponent>
            }
          />
        </>
      )}
      {pages.signalEventsAnalytics?.enabled && signalEventsAnalytics && (
        <>
          <Route
            path={routes.signalEventAnalytics}
            element={
              <SecureComponent>
                <SignalEventsAnalytics />
              </SecureComponent>
            }
          />
          <Route
            path={routes.signalEventsAnalyticsDetails}
            element={
              <SecureComponent>
                <SingleSignalEventOccurrence />
              </SecureComponent>
            }
          />
        </>
      )}
      {issuesEnabled && (
        <>
          <Route
            path={routes.issues}
            element={
              <SecureComponent>
                <Issues />
              </SecureComponent>
            }
          />
          <Route
            path={routes.issue}
            element={
              <SecureComponent>
                <Issue />
              </SecureComponent>
            }
          />
        </>
      )}
      {suggestedIssuesEnabled && (
        <>
          <Route
            path={routes.suggestedIssues}
            element={
              <SecureComponent>
                <Issues />
              </SecureComponent>
            }
          />
          <Route
            path={routes.suggestedIssue}
            element={
              <SecureComponent>
                <SuggestedIssue />
              </SecureComponent>
            }
          />
          <Route
            path={routes.suggestedIssueLatestRun}
            element={
              <SecureComponent>
                <SuggestedIssueLatestRun />
              </SecureComponent>
            }
          />
        </>
      )}
      {pages.customSignalEvents?.enabled && customSignalEvents && (
        <>
          <Route
            path={routes.customSignalEvents}
            element={
              <SecureComponent>
                <CustomSignalEvents />
              </SecureComponent>
            }
          />
          <Route
            path={routes.newCustomSignalEvent}
            element={
              <SecureComponent>
                <CustomSignalEventForm pageTitle="New Custom Signal Event" />
              </SecureComponent>
            }
          />
          <Route
            path={routes.customSignalEvent}
            element={
              <SecureComponent>
                <CustomSignalEvent />
              </SecureComponent>
            }
          />
        </>
      )}
      {pages.events?.enabled && (
        <Route
          path={routes.serviceRecords}
          element={
            <SecureComponent>
              <ServiceRecords />
            </SecureComponent>
          }
        />
      )}
      {rbac && rbacGroups && (
        <>
          <Route
            path={routes.groups}
            element={
              <SecureComponent>
                <Groups />
              </SecureComponent>
            }
          />
          <Route
            path={routes.group}
            element={
              <SecureComponent>
                <Group />
              </SecureComponent>
            }
          />
        </>
      )}
      <Route path={routes.version} element={<Version />} />
      <Route
        path={routes.notFound}
        element={
          <SecureComponent>
            <NotFound />
          </SecureComponent>
        }
      />
      {pages.chatBot?.enabled && (
        <Route
          path={routes.chatBot}
          element={
            <SecureComponent>
              <ChatBot />
            </SecureComponent>
          }
        />
      )}
    </Routes>
  );
};
export default AppRoutes;
