import { GraphStateType } from "duck/graph/state";
import { Document } from "@langchain/core/documents";
import { END } from "@langchain/langgraph/web";
import { ChatOpenAI } from "@langchain/openai";

import {
  vectorStoreSearch,
  VectorStoreSearchParameters,
} from "shared/api/vectorstore/api";

import { MODELSPEC, OPENAI_API_KEY } from "./constants";

// Nodes that can be routed through a tool call with the same name
export const ToolCallRoutableNodeNames = {
  RAG: "rag",
  REJECT_CLARIFY: "rejectClarify",
  CLAIM_ANALYTICS: "claimAnalytics",
  SIGNAL_EVENT_ANALYTICS: "signalEventAnalytics",
  SUPERVISOR: "supervisor",
} as const;
export type ToolCallRoutableNodeNamesType =
  (typeof ToolCallRoutableNodeNames)[keyof typeof ToolCallRoutableNodeNames];

// Combine the values for all NodeNames
export const NodeNames = {
  ...ToolCallRoutableNodeNames,
  DOCUMENT_RETRIEVAL: "documentRetrieval",
  CLAIM_ANALYTICS_TOOLS: "claimAnalyticsTools",
  SIGNAL_EVENT_ANALYTICS_TOOLS: "signalEventAnalyticsTools",
  RESPOND_TO_USER_TOOL: "respondToUserTool",
} as const;
export type NodeNamesType = (typeof NodeNames)[keyof typeof NodeNames];

export const GenericToolNodeName = "tools";

type NextNodeType =
  | ToolCallRoutableNodeNamesType
  | typeof GenericToolNodeName
  | typeof END;

/**
 * Respond based on the last message's tool call
 * @summary Conditional routing function for the agent
 * @param state
 * @returns An indicator of which node to route to
 */
export const getNextNode = (state: GraphStateType): NextNodeType => {
  const { messages } = state;
  const lastMessage = messages[messages.length - 1];
  if (
    "tool_calls" in lastMessage &&
    Array.isArray(lastMessage.tool_calls) &&
    lastMessage.tool_calls?.length
  ) {
    const toolCallName = lastMessage.tool_calls[0].name;
    console.debug("getNextNode: Tool call name:", toolCallName);
    if (Object.values(ToolCallRoutableNodeNames).includes(toolCallName)) {
      console.debug("getNextNode: Routing to", toolCallName);
      return toolCallName;
    }
    console.debug("getNextNode: Routing to tools");
    return GenericToolNodeName;
  }

  // this should never happen but just in case
  console.error(
    "getNextNode: there is no tool call in the last message, ending the conversation"
  );
  return END;
};

export const getLLM = () => {
  return new ChatOpenAI({
    openAIApiKey: OPENAI_API_KEY,
    model: MODELSPEC.modelName,
    temperature: MODELSPEC.temperature,
    modelKwargs: MODELSPEC.modelKwargs,
  });
};

export const retrieveRelevantDocuments = async (
  query: string,
  source?: string,
  k?: number,
  distanceThreshold?: number
): Promise<Document[]> => {
  console.debug("Retrieving relevant documents", {
    query,
    source,
    k,
    distanceThreshold,
  });

  const params: VectorStoreSearchParameters = {
    query,
    k,
    distanceThreshold,
    source,
  };
  const { data } = await vectorStoreSearch(params);

  if (!data) {
    console.error("No documents found for the given question.");
    return [];
  }

  const documents = data.map((result) => {
    const { documentID, document, title, url, metadata } = result;
    console.log("Document retrieved:", {
      documentID,
      document,
      title,
      url,
      source,
      metadata,
    });

    let parsedMetadata: Record<string, any>;
    try {
      parsedMetadata = JSON.parse(metadata);
    } catch (error) {
      console.error("Failed to parse metadata:", error);
      parsedMetadata = { rawMetadata: metadata };
    }

    return new Document({
      pageContent: document,
      metadata: { title, url, source, ...parsedMetadata },
      id: documentID,
    });
  });

  return documents;
};

/**
 * @summary Format the documents for the LLM
 * @param docs The documents to format
 * @returns The formatted documents
 */

export const formatDocs = (docs: Document[]) => {
  return JSON.stringify(
    docs.map((doc) => ({
      pageContent: doc.pageContent,
      url: doc.metadata.url,
      title: doc.metadata.title,
    }))
  );
};
