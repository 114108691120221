import { validateFilter } from "duck/graph/nodes/ClaimAnalyticsAgent/utils";
import { PageHandler } from "duck/graph/PageHandler";
import { z } from "zod";
import { DynamicStructuredTool, tool } from "@langchain/core/tools";

import { CLAIMS_PAGE_KEY } from "pages/ClaimAnalytics/constants";

import { getFiltersKey } from "features/ui/Filters/utils";

const updateClaimsFilterSchema = z.object({
  claimsFilter: z
    .string()
    .optional()
    .describe(
      "Filter string for claims. Use empty string to clear/reset filter. Call retrieveClaimAttributes to get the list of available attributes."
    ),
});

type UpdateClaimsFilter = z.infer<typeof updateClaimsFilterSchema>;

const getUpdateClaimsFilterTool = (
  pageHandler: PageHandler
): DynamicStructuredTool => {
  const updateClaimsFilter = async ({
    claimsFilter,
  }: UpdateClaimsFilter): Promise<string> => {
    console.debug("[CA]: Updating claims filters", { claimsFilter });

    const filterQueryString = claimsFilter || "";

    await validateFilter({ claimsFilter: filterQueryString });

    pageHandler.updateFilter(filterQueryString, getFiltersKey(CLAIMS_PAGE_KEY));
    return "Queued update claims filter";
  };

  return tool(updateClaimsFilter, {
    name: "queueUpdateClaimsFilter",
    description:
      "Call this tool to queue an action that updates the claims filters given a text query. Only call this for claim attributes.",
    schema: updateClaimsFilterSchema,
  });
};

export default getUpdateClaimsFilterTool;
