import { PageHandler } from "duck/graph/PageHandler";
import { DuckGraphParams } from "duck/graph/types";
import { z } from "zod";
import { DynamicStructuredTool, tool } from "@langchain/core/tools";

import {
  CLAIMS_PAGE_KEY,
  CLAIMS_TAB_KEY,
} from "pages/ClaimAnalytics/constants";
import { CLAIMS_CHART_OPTIONS_KEY } from "pages/ClaimAnalytics/tabPages/Claims/ClaimsChart";
import { claimsActionIds, ClaimsActionIdType } from "pages/utils";

const actionDescriptions: Map<ClaimsActionIdType, string> = new Map([
  [
    "y",
    "The metric to be displayed on the y-axis of the chart. Default value: 'cumulativeIPTV'.",
  ],
]);

const getGoToClaimsTabTool = (
  handlers: PageHandler,
  claimsChartOptions: DuckGraphParams["availableData"]["claimAnalytics"]["claimsChartOptions"]
): DynamicStructuredTool => {
  type SchemaDefinition = {
    [K in ClaimsActionIdType]: z.ZodOptional<z.ZodEnum<[string, ...string[]]>>;
  };

  const schemaDefinition = claimsActionIds.reduce((acc, actionId) => {
    return {
      ...acc,
      [actionId]: z
        .enum(claimsChartOptions[actionId])
        .optional()
        .describe(actionDescriptions.get(actionId) ?? ""),
    };
  }, {} as SchemaDefinition);

  const goToClaimsTabSchema = z.object(schemaDefinition);

  type GoToClaimsTab = z.infer<typeof goToClaimsTabSchema>;

  const goToClaimsTab = ({ y }: GoToClaimsTab): string => {
    console.debug("Navigating to Claims tab with y: " + y);

    const nonNullY = y ?? claimsChartOptions.y[0];
    if (!claimsChartOptions.y.includes(nonNullY)) {
      throw new Error(
        `Invalid y option: ${nonNullY}. Valid options: ${claimsChartOptions.y.join(", ")}`
      );
    }

    handlers.updateTabChartSettings(CLAIMS_PAGE_KEY, CLAIMS_TAB_KEY, {
      [CLAIMS_CHART_OPTIONS_KEY]: [{ id: "y", optionId: nonNullY }],
    });

    return "queued navigation to Claims tab";
  };

  return tool(goToClaimsTab, {
    name: "queueGoToClaimsTab",
    description: `Call this tool to queue a navigation to the Claims tab.`,
    schema: goToClaimsTabSchema,
  });
};

export default getGoToClaimsTabTool;
