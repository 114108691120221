import {
  ChangeEvent,
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { DuckMessagesContext } from "duck/context/DuckMessagesContextWrapper";
import callGraph from "duck/graph";
import { UIHandlers } from "duck/graph/types";
import { jwtDecode } from "jwt-decode";
import { useOktaAuth } from "@okta/okta-react";

import { JWT } from "shared/types";

import { DUCK_GENERIC_ERROR_MESSAGE } from "./constants";
import { useAgentData } from "./hooks";
import { LocationInfo, Reload } from "./types";
import { captureScreenshot } from "./utils";

type DuckTextInputProps = {
  threadId: string;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setPendingAction: Dispatch<SetStateAction<boolean>>;
  acquireLocationInformation: (reset: boolean) => LocationInfo;
  clearLocationInfo: () => void;
};

const DuckTextInput = ({
  threadId,
  loading,
  setLoading,
  setPendingAction,
  acquireLocationInformation,
  clearLocationInfo,
}: DuckTextInputProps) => {
  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getAccessToken() || "";
  const { tenant, sub: user }: JWT = jwtDecode(accessToken);

  const [utterance, setUtterance] = useState("");

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const { messages, addMessage } = useContext(DuckMessagesContext);

  const { availableData, getPageState } = useAgentData();

  const handleAgentResponse = (message: string) => {
    addMessage({
      author: "agent",
      message,
    });
  };

  const uiHandlers: UIHandlers = {
    setAgentResponse: handleAgentResponse,
  };

  const submit = async (): Promise<void> => {
    if (!availableData.vinView) {
      console.error("The available data needed by the agent has not loaded");
      return;
    }

    const currentUtterance = utterance.trim();

    const priorMessages = [...messages];

    addMessage({
      author: "human",
      message: currentUtterance,
    });

    setUtterance("");
    setLoading(true);
    setPendingAction(false);
    clearLocationInfo();

    // Make sure that the page state is up to date
    const currentState = getPageState();
    // console.log({ currentState, availableData });

    const screenshot = await captureScreenshot();

    try {
      await callGraph({
        text: currentUtterance,
        messageHistory: priorMessages,
        uiHandlers,
        threadId,
        tenant: String(tenant),
        user: String(user),
        currentState,
        availableData,
        screenshot,
      });

      const { reloadRequired } = acquireLocationInformation(false);
      if (reloadRequired === Reload.HARD || reloadRequired === Reload.SOFT) {
        setPendingAction(true);
      }
    } catch (error) {
      console.error(`${new Date().getTime()} error`, error);
      addMessage({
        author: "agent",
        message: DUCK_GENERIC_ERROR_MESSAGE,
      });
    } finally {
      setLoading(false);
    }
  };

  const adjustHeight = useCallback(() => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    textarea.style.height = "auto";
    textarea.style.height = `${Math.min(70, textarea.scrollHeight)}px`;
    // Scroll to the bottom. Without this call, the textarea does not scroll all
    // the way to the bottom when a new line is added.
    textarea.scrollTop = textarea.scrollHeight;
  }, []);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    setUtterance(e.target.value);
    adjustHeight();
  };

  const assignFocus = async () => {
    // This brief delay is necessary for this to operate correctly
    await new Promise((resolve) => setTimeout(resolve, 10));
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };

  const handleKeyDown = async (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey && !!utterance.trim()) {
      event.preventDefault(); // Prevent default to avoid new line in multiline input
      await submit();
      await assignFocus();
    }
  };

  // The use of the MUI TextField component leads to runtime errors with the message of:
  // "ResizeObserver loop completed with undelivered notifications"
  // This happens when horizontally resizing the component over the CSS breakpoint boundary.
  // Using the native textarea component avoids this issue.
  return (
    <textarea
      ref={textareaRef}
      value={utterance}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      placeholder="Ask something"
      disabled={loading}
      rows={1}
      style={{
        width: "100%",
        backgroundColor: "white",
        padding: "4px 8px",
        borderRadius: "0.375rem",
        resize: "none",
        overflow: "auto",
      }}
    />
  );
};

export default DuckTextInput;
