import { validateFilter } from "duck/graph/nodes/ClaimAnalyticsAgent/utils";
import { PageHandler } from "duck/graph/PageHandler";
import { z } from "zod";
import { DynamicStructuredTool, tool } from "@langchain/core/tools";

import { VEHICLES_PAGE_KEY } from "pages/ClaimAnalytics/constants";

import { getFiltersKey } from "features/ui/Filters/utils";

const updateVehiclesFilterSchema = z.object({
  vehiclesFilter: z
    .string()
    .optional()
    .describe(
      "Filter string for vehicles. Use empty string to clear/reset filter. Call retrieveVehicleAttributes to get the list of available attributes."
    ),
});

type UpdateVehiclesFilter = z.infer<typeof updateVehiclesFilterSchema>;

const getUpdateVehiclesFilterTool = (
  pageHandler: PageHandler
): DynamicStructuredTool => {
  const updateVehiclesFilter = async ({
    vehiclesFilter,
  }: UpdateVehiclesFilter): Promise<string> => {
    console.debug("[CA]: Updating vehicles filters", { vehiclesFilter });
    const filterQueryString = vehiclesFilter || "";

    await validateFilter({ vehiclesFilter: filterQueryString });

    pageHandler.updateFilter(
      filterQueryString,
      getFiltersKey(VEHICLES_PAGE_KEY)
    );
    return "Queued update vehicles filter";
  };

  return tool(updateVehiclesFilter, {
    name: "queueUpdateVehiclesFilter",
    description:
      "Call this tool to queue an action that updates the vehicles filters given a text query. Only call this for vehicle attributes.",
    schema: updateVehiclesFilterSchema,
  });
};

export default getUpdateVehiclesFilterTool;
