import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { GetSuggestedIssuesOverviewRequest } from "shared/api/issues/api";
import { useSuggestedIssuesOverview } from "shared/api/issues/hooks";
import { APIFilter } from "shared/api/utils";
import { useCustomLocalStorageState } from "shared/hooks";
import {
  BucketBySuggestedIssuesEnum,
  GroupBySuggestedIssuesEnum,
  SuggestedIssueMeasuresEnum,
  ValueType,
} from "shared/types";

import Accordion from "features/ui/Accordion";
import APIError from "features/ui/APIError";
import ChartActions from "features/ui/charts/Actions/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/Actions/ChartActionsWrap";
import {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/Actions/types";
import BarChart from "features/ui/charts/BarChart";
import { getDefaultActions } from "features/ui/charts/utils";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import { UseFilterSortState } from "features/ui/Filters/types";
import { SelectOption } from "features/ui/Select";

import {
  DASHBOARD_GROUP_BY_KEY,
  SUGGESTED_ISSUES_CHART_ACTIONS,
  SUGGESTED_ISSUES_DASHBOARD_KEY,
} from "./constants";
import { useBarSelection } from "./hooks";
import {
  getAxisKeyLabelFromActions,
  getFilterFromColumns,
  getLongestValue,
  getMeasureLabel,
  getMetricsRequestParams,
  transformSuggestedIssuesOverviewData,
} from "./utils";

interface Props {
  filterSortState: UseFilterSortState;
  selectedOptions: SelectedChartOptions[];
  setSelectedOptions: Dispatch<SetStateAction<SelectedChartOptions[]>>;
  setSelectedBarFilters: (filterState: FilterGroupState | undefined) => void;
  staticFilters?: APIFilter[];
}

const getAvailableActions = (
  selectedOptions: SelectedChartOptions[]
): ChartAction[] => {
  // we want to make sure that user cannot select the same option for both groupBy and bucketBy which returns an API error
  const groupByOption = selectedOptions.find(
    ({ id }) => id === "groupBy"
  )?.optionId;
  const bucketByOption = selectedOptions.find(
    ({ id }) => id === "bucketBy"
  )?.optionId;
  const measureOption = selectedOptions.find(
    ({ id }) => id === "measure"
  )?.optionId;

  // update chart actions to remove the selected bucketBy option from groupBy options and vice versa
  return SUGGESTED_ISSUES_CHART_ACTIONS.map((action) => {
    if (
      measureOption === "count" &&
      ["lookbackWindow", "valueType"].includes(action.id)
    ) {
      return undefined;
    }

    if (action.id === "groupBy" && bucketByOption) {
      return {
        ...action,
        options: action.options?.filter(
          (option) => option.id !== bucketByOption
        ),
      };
    }
    if (action.id === "bucketBy" && groupByOption) {
      return {
        ...action,
        options: action.options?.filter(
          (option) => option.id !== groupByOption
        ),
      };
    }
    return action;
  }).filter(Boolean) as ChartAction[];
};

const SuggestedIssuesDashboard = ({
  filterSortState,
  selectedOptions,
  setSelectedOptions,
  setSelectedBarFilters,
  staticFilters,
}: Props) => {
  const [
    suggestedIssuesDashboardExtended,
    setSuggestedIssuesDashboardExtended,
  ] = useCustomLocalStorageState(SUGGESTED_ISSUES_DASHBOARD_KEY, {
    defaultValue: false,
  });

  const [availableChartActions, setAvailableChartActions] = useState<
    ChartAction[]
  >(getAvailableActions(selectedOptions));

  const { filters, resetFilterSortState, updateFilters } = filterSortState;

  const filtersQuery = getFiltersQuery(filters, staticFilters);

  const { axisKey: axisKeyBucketBy, axisValue: axisValueBucketBy } =
    getAxisKeyLabelFromActions(
      selectedOptions,
      SUGGESTED_ISSUES_CHART_ACTIONS,
      "bucketBy"
    );

  const { axisKey: axisKeyGroupBy, axisValue: axisValueGroupBy } =
    getAxisKeyLabelFromActions(
      selectedOptions,
      SUGGESTED_ISSUES_CHART_ACTIONS,
      "groupBy"
    );

  const selectedGroupByAttribute: SelectOption = useMemo(
    () => ({ id: axisKeyGroupBy, value: axisValueGroupBy }) as SelectOption,
    [axisKeyGroupBy, axisValueGroupBy]
  );

  const { axisKey: measureKey, axisValue: measure } =
    getAxisKeyLabelFromActions(
      selectedOptions,
      SUGGESTED_ISSUES_CHART_ACTIONS,
      "measure"
    );

  const { lookbackWindow, valueType } = getMetricsRequestParams(
    selectedOptions,
    SUGGESTED_ISSUES_CHART_ACTIONS
  );
  const valueTypeKey = valueType as ValueType;

  const requestParams: GetSuggestedIssuesOverviewRequest = {
    measure: measureKey as SuggestedIssueMeasuresEnum,
    bucketBy: axisKeyBucketBy as BucketBySuggestedIssuesEnum,
    lookbackWindow,
    valueType: valueTypeKey,
    filter: filtersQuery,
    groupBy: axisKeyGroupBy as GroupBySuggestedIssuesEnum,
  };

  const { data, isLoading, error } = useSuggestedIssuesOverview(requestParams);

  const measureLabel = getMeasureLabel(
    SUGGESTED_ISSUES_CHART_ACTIONS,
    valueTypeKey,
    measureKey,
    lookbackWindow?.toString(),
    measure
  );

  const { chartData, yAxisBars, nameIDMapping } =
    transformSuggestedIssuesOverviewData(
      data,
      axisKeyBucketBy as BucketBySuggestedIssuesEnum,
      axisKeyGroupBy as GroupBySuggestedIssuesEnum,
      measureKey,
      measureLabel,
      valueTypeKey
    );

  const {
    selectedColumns,
    showContextMenu,
    handleOnChartClick,
    handleOnBarClick,
    onBarRightClick,
    contextMenu,
  } = useBarSelection({
    selectedGroupByAttribute,
    selectedBucketByKey: axisKeyBucketBy,
    menuOffsetElement: document.querySelector(
      '[data-testid="accordion-suggestedIssuesDashboard"]'
    ),
    nameIDMapping: nameIDMapping,
    updateFilters: updateFilters,
    existingFilters: filters,
  });

  // left click - selectedColumns filter propagated to the SuggestedIssuesTable
  useEffect(() => {
    const newBarsFilter = getFilterFromColumns(
      selectedColumns,
      axisKeyGroupBy,
      axisKeyBucketBy,
      nameIDMapping
    );
    setSelectedBarFilters(newBarsFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedColumns]);

  const handleOnOptionChange = (selectedOptions: SelectedChartOptions[]) => {
    const availableActions = getAvailableActions(selectedOptions);

    setAvailableChartActions(availableActions);

    const defaultActions = getDefaultActions(availableActions);
    const selectedActionIds = selectedOptions.map(({ id }) => id);
    const missingSelectedOptions = availableActions.filter(
      ({ id }) => !selectedActionIds.includes(id)
    );

    const newSelectedOptions = [...selectedOptions];
    missingSelectedOptions.forEach((o) =>
      newSelectedOptions.push({
        id: o.id,
        optionId: defaultActions.find((x) => x.id === o.id)?.optionId!,
      })
    );

    setSelectedOptions(newSelectedOptions);
  };

  const baseChartTitle = `${measureLabel} grouped by ${axisValueGroupBy}`;
  const chartTitle =
    axisKeyBucketBy === "none"
      ? baseChartTitle
      : `${baseChartTitle} and bucketed by ${axisValueBucketBy}`;

  const longestChartValue = getLongestValue(chartData);

  return (
    <Accordion
      id="suggestedIssuesDashboard"
      title={chartTitle}
      expanded={suggestedIssuesDashboardExtended}
      onChange={() =>
        setSuggestedIssuesDashboardExtended(!suggestedIssuesDashboardExtended)
      }
      arrowPosition="left"
      rightContent={
        <ChartActionsWrap>
          <ChartActions
            actions={availableChartActions}
            selectedOptions={selectedOptions}
            onOptionChange={handleOnOptionChange}
          />
        </ChartActionsWrap>
      }
    >
      <div>
        {showContextMenu && contextMenu}
        {!error && (
          <div>
            {isLoading && <Skeleton height={300} />}
            {chartData && chartData.length > 0 ? (
              <div>
                <BarChart
                  data={chartData}
                  margin={{
                    left: longestChartValue.toString().length * 3,
                  }}
                  xAxisKey={DASHBOARD_GROUP_BY_KEY}
                  xAxisProps={{
                    angle: -10,
                    height: 50,
                    dy: 10,
                  }}
                  yAxisBars={yAxisBars}
                  selectedColumns={selectedColumns}
                  onBarClick={handleOnBarClick}
                  onBarRightClick={onBarRightClick}
                  onChartClick={handleOnChartClick}
                  enableBarGroupHover={true}
                />
              </div>
            ) : (
              <div className="py-4 text-gray-400 text-sm">No data.</div>
            )}
          </div>
        )}
        {error && (
          <APIError
            error={error}
            onBadRequest={() => {
              resetFilterSortState();
              setSelectedOptions(
                getDefaultActions(SUGGESTED_ISSUES_CHART_ACTIONS)
              );
            }}
          />
        )}
      </div>
    </Accordion>
  );
};

export default SuggestedIssuesDashboard;
