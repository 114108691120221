import axios from "axios";
import { GraphStateType } from "duck/graph/state";
import { formatDocs, retrieveRelevantDocuments } from "duck/graph/utils";
import { BindToolsInput } from "@langchain/core/language_models/chat_models";
import { AIMessage, HumanMessage, ToolMessage } from "@langchain/core/messages";
import { ChatPromptTemplate } from "@langchain/core/prompts";
import { Runnable, RunnableConfig } from "@langchain/core/runnables";
import { ChatOpenAI, ChatOpenAICallOptions } from "@langchain/openai";

import client from "shared/api/axios";

import {
  MAX_WINDOW_SIZE,
  MIN_WINDOW_SIZE,
} from "features/ui/Filters/FilterTypes/RelatesFilter/constants";
import {
  FilterOperator,
  RelatesFilterState,
  RelatesFilterWindowDirection,
  RelatesFilterWindowDirectionType,
  signalEventsFilterOperators,
  SignalEventsFilterOperatorsType,
} from "features/ui/Filters/types";

export type NodeOutputType = Partial<GraphStateType>;

export interface NodeType {
  (state: GraphStateType, config?: RunnableConfig): Promise<NodeOutputType>;
}

/**
 * Invokes an agent node with the provided state and configuration.
 *
 * @param agent - The agent to be invoked, which implements the Runnable interface.
 * @param data - Optional additional data to be passed to the agent.
 * @param name - Optional name to be assigned to the response message.
 * @returns A function that takes the current graph state and configuration, and returns a promise resolving to the node output.
 *
 * @param state - The current state of the graph.
 * @param config - Optional configuration object for the agent invocation.
 * @returns A promise that resolves to the node output containing the updated messages array.
 */
export const invokeAgentNode =
  (agent: Runnable, data?: any, name?: string) =>
  async (
    state: GraphStateType,
    config: RunnableConfig = {}
  ): Promise<NodeOutputType> => {
    const { messages, pageState, documents } = state;

    // Check if the last message is an AIMessage with non-empty tool calls
    const lastMessage = messages[messages.length - 1];
    const toolMessage = [];
    if (
      lastMessage instanceof AIMessage &&
      lastMessage.tool_calls &&
      lastMessage.tool_calls.length > 0
    ) {
      // Add a tool message to the messages array
      const toolCall = lastMessage.tool_calls[0];
      toolMessage.push(
        new ToolMessage({
          name: toolCall.name,
          content: "Success",
          tool_call_id: String(toolCall.id),
          status: "success",
        })
      );
    }

    const agentMessage = await agent.invoke(
      {
        messages: [...messages, ...toolMessage],
        current_state: JSON.stringify(pageState),
        context: formatDocs(documents),
        ...data,
      },
      config
    );
    agentMessage.name = name;

    return {
      messages: [...toolMessage, agentMessage],
    };
  };

/**
 * @summary Create and return the agent responsible for processing the utterance.
 * @param llm The LLM agent that processes the utterance
 * @param tools The tools available to the LLM
 * @param prompt The prompt to send to the LLM
 * @param toolsArgs The arguments to pass to the tools
 * @returns The agent responsible for processing the utterance.
 */
export const createAgent = (
  llm: ChatOpenAI<ChatOpenAICallOptions>,
  tools: BindToolsInput[],
  prompt: ChatPromptTemplate,
  toolsArgs?: Record<string, any>
): Runnable => {
  const agent = prompt.pipe(llm.bindTools(tools, toolsArgs));
  return agent;
};

/**
 * @summary Create and return the agent that strictly calls tools. The Agent calls the end tool when finished.
 * @param llm The LLM agent that processes the utterance
 * @param tools The tools available to the LLM
 * @param prompt The prompt to send to the LLM
 * @returns The runnable agent responsible for rejecting or clarifying the user's utterance.
 */
export const createStrictToolCallingAgent = (
  llm: ChatOpenAI<ChatOpenAICallOptions>,
  tools: BindToolsInput[],
  prompt: ChatPromptTemplate,
  parallelToolCalls: boolean = true
): Runnable => {
  return createAgent(llm, tools, prompt, {
    strict: true,
    tool_choice: "required",
    parallel_tool_calls: parallelToolCalls,
  });
};

/**
 * Creates a retrieval node that fetches relevant documents based on the latest message content.
 *
 * @param source - The source from which to retrieve documents.
 * @param k - The number of top relevant documents to retrieve.
 * @param distanceThreshold - The threshold for document relevance.
 * @returns A function that takes the current graph state and an optional configuration object,
 * and returns a promise resolving to an object containing the retrieved documents.
 */
export const createDocumentRetrievalNode =
  (source: string, k: number, distanceThreshold: number): NodeType =>
  async (
    state: GraphStateType,
    config: RunnableConfig = {}
  ): Promise<NodeOutputType> => {
    const { messages } = state;

    const query = (
      messages[messages.length - 1] as HumanMessage
    ).content.toString();
    const documents = await retrieveRelevantDocuments(
      query,
      source,
      k,
      distanceThreshold
    );

    return {
      documents,
    };
  };

// Define a generic type for the filter request
type FilterRequest = Record<string, any>;

/**
 * Validate the filter parameters by making an API request with them.
 * If they are invalid, the API will respond with a 400 status code,
 * which we consider to be an error.
 */
export const validateApiRequest = async <T extends FilterRequest>(
  params: T,
  getRequestURI: (params: T) => string
): Promise<void> => {
  const url = getRequestURI(params);
  try {
    const validationResponse = await client.get(url);
    if (validationResponse.status < 200 || validationResponse.status >= 300) {
      console.error(
        "Invalid filter parameter validation status code",
        validationResponse
      );
      throw new Error(
        `The filter parameters of "${JSON.stringify(params)}" are invalid.
          The API server responded with a status of "${validationResponse.status}".
          The message from the API server is: "${validationResponse.data}".
          Please try a different filter.`
      );
    }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.error("Invalid filter parameters", error.response);
      throw new Error(
        `The filter parameters of "${JSON.stringify(params)}" are invalid.
          The API server responded with a status of "${error.response.status}".
          The error message from the API server is: "${JSON.stringify(error.response.data)}".
          Please try a different filter.`
      );
    } else {
      // This error did not originate from Axios. It is a mystery, so just rethrow it.
      throw error;
    }
  }
};

/**
 * @param windowSize The number of days in the signal event window.
 * @throws If the window size is invalid, a descriptive error will be thrown.
 */
export const validateSignalEventOccurrencesWindowSize = (
  windowSize: number
): void => {
  if (windowSize < MIN_WINDOW_SIZE || windowSize > MAX_WINDOW_SIZE) {
    throw new Error(
      `The window size for signal event occurrences must be between ${MIN_WINDOW_SIZE} and ${MAX_WINDOW_SIZE} days. The value of ${windowSize} is not valid.`
    );
  }
};

/**
 * Validate the signal event filter operator and values.
 *
 * @param operator The operator to filter the signal event IDs.
 * @param values The list of values to filter the signal event IDs.
 * @throws If the operator or values are invalid, an error will be thrown.
 * @returns The operator and values, if they are valid.
 */
export const validateSignalEventFilterOperatorValues = (
  operator: SignalEventsFilterOperatorsType,
  values: string[]
) => {
  if (!signalEventsFilterOperators.includes(operator)) {
    throw new Error(
      `The signal event filter operator must be one of ${signalEventsFilterOperators.join(
        ", "
      )}. The value of ${operator} is not valid.`
    );
  }

  if (
    operator === FilterOperator.NOT_FILTERED ||
    operator === FilterOperator.IS_NOT_EMPTY
  ) {
    // we can automatically ignore values
    return { operator, values: ["null"] };
  }

  // Ensure all values are non-empty strings
  const sanitizedValues = values.map((value) => {
    if (value.trim() === "") {
      throw new Error(
        `The signal event filter values must be non-empty strings. The value of ${JSON.stringify(values)} is not valid.`
      );
    }
    return value.trim();
  });
  return { operator, values: sanitizedValues };
};

/**
 * @param operator
 * @param values
 * @param windowSize
 * @param windowDirection
 * @returns An object that can be used to filter signal events.
 */
export const createRelatedSignalEventFilter = (
  operator: FilterOperator,
  values: string[],
  windowSize: number | undefined,
  windowDirection?: RelatesFilterWindowDirectionType
): RelatesFilterState => ({
  operator: "occurs",
  options: {
    windowSize: String(windowSize ?? 30),
    windowDirection: windowDirection ?? RelatesFilterWindowDirection.BEFORE,
    windowType: "days",
  },
  filters: {
    id: "group-0",
    type: "group",
    anyAll: "all",
    children: [
      {
        id: "row-0",
        type: "row",
        attribute: "signalEventID",
        operator,
        values,
      },
    ],
  },
});
