import { useFlags } from "launchdarkly-react-client-sdk";

import { FailureMode } from "shared/api/failureModes/api";
import { SHORT_DATE_FORMAT } from "shared/constants";
import { formatDate } from "shared/utils";

import VehicleFilters from "pages/Vehicles/VehicleFilters";

import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";

import FailureModePredictionsTable from "./PredictionsTable";
import RiskDistribution from "./RiskDistribution";

interface Props {
  failureMode: FailureMode;
}

const ResultsTab = ({ failureMode }: Props) => {
  const pageKey = `failure_mode_predictions_vehicles_${failureMode.ID}`;

  const { legacyFailureModes } = useFlags();

  const vehiclesFiltersFilterSortState = useFilterSortState({
    pageKey: pageKey,
    pendingFiltersLocalStorageKey: getPendingFiltersKey(pageKey),
  });

  return (
    <>
      <div className="flex items-center justify-between">
        <VehicleFilters
          vehiclesFilterSortState={vehiclesFiltersFilterSortState}
          hideCollectionFilter
        />
        {failureMode.inferenceRunDate && (
          <div className="text-xs text-gray-500 pr-3">
            <strong>Last Run On</strong>:{" "}
            {formatDate(failureMode.inferenceRunDate, SHORT_DATE_FORMAT, true)}
          </div>
        )}
      </div>
      {legacyFailureModes && (
        <RiskDistribution
          vehiclesFilter={vehiclesFiltersFilterSortState.filters}
          failureModeID={failureMode.ID}
        />
      )}
      <FailureModePredictionsTable
        failureModeID={failureMode.ID}
        vehicleFilters={vehiclesFiltersFilterSortState.filters}
      />
    </>
  );
};

export default ResultsTab;
