import "./duck-loading.css";

import DuckLogo from "./duck-logo.svg";

const DuckLoadingAnimation = () => (
  <div className="container">
    <div className="animate-duck-loading">
      <img src={DuckLogo} alt="Duck Logo" />
    </div>
  </div>
);

export default DuckLoadingAnimation;
