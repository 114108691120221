import { listAlertDefinitionValues } from "shared/api/alertDefinitions/api";
import {
  APIListValuesRequest,
  APIListValuesResponse,
  APISuccessResponse,
  Collection,
  getCollection,
  listTransportCategories,
  listVehicleCollections,
  listVehicleCollectionsValues,
  listVehicleECUsValues,
  listVehicleTagsValues,
  VehicleGenericCategory,
} from "shared/api/api";
import { listOrderedValuesValues } from "shared/api/orderedValues/api";
import { SENSORS_LIMIT } from "shared/constants";
import { excludeNulls } from "shared/utils";

import {
  filterStateToFilterGroupState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import { FilterOperator, FilterState } from "features/ui/Filters/types";
import { SelectOption } from "features/ui/Select";

import { listCalculatedAttributesValues } from "./calculatedAttributes/api";
import { listClaimsValues } from "./claims/api";
import { listCustomRecordsValues } from "./customRecords/api";
import { listCustomSignalEventDefinitionValues } from "./customSignalEvents/hooks";
import {
  APIListValuesFailureModeEventRequest,
  listFailureModeEventsValues,
  listFailureModeRiskModelPredictionsValues,
  listFailureModesValues,
} from "./failureModes/api";
import { listInspectionsValues } from "./inspections/api";
import { listIssues, listIssuesValues } from "./issues/api";
import { Group, listGroups, listGroupsValues } from "./rbac/api";
import { listRepairsValues } from "./repairs/api";
import { getSensors, listSensorsReadingValues } from "./sensors/api";
import { listServicePlansValues } from "./servicePlans/api";
import { listServiceRecommendationsValues } from "./serviceRecommendations/api";
import { listServiceRecordsValues } from "./serviceRecords/api";
import {
  listDealerValues,
  listSignalEventsOccurrencesValues,
  listSignalEventsValues,
} from "./signalEvents/api";
import { listSuggestedIssuesValues } from "./suggestedIssues/api";
import {
  FailureMode,
  listFailureModePredictionsValues,
  listFailureModes,
  APIListValuesFailureModeEventRequest as v0_APIListValuesFailureModeEventRequest,
  listFailureModeEventsValues as v0_listFailureModeEventsValues,
} from "./v0_failureModes/api";
import { listVehiclesValues } from "./vehicles/api";

export const vehicleLoadDataFunc = (args: APIListValuesRequest) => {
  return listVehiclesValues(args).then(transformListValuesAPIResponseFunc);
};

export const vehicleTagsLoadDataFunc = (args: APIListValuesRequest) =>
  listVehicleTagsValues(args).then(transformListValuesAPIResponseFunc);

export const vehicleECUsLoadDataFunc = (args: APIListValuesRequest) =>
  listVehicleECUsValues(args).then(transformListValuesAPIResponseFunc);

export const claimsLoadDataFunc = (args: APIListValuesRequest) =>
  listClaimsValues(args).then(transformListValuesAPIResponseFunc);

export const inspectionsLoadDataFunc = (args: APIListValuesRequest) =>
  listInspectionsValues(args).then(transformListValuesAPIResponseFunc);

export const customRecordsLoadDataFunc = (args: APIListValuesRequest) =>
  listCustomRecordsValues(args).then(transformListValuesAPIResponseFunc);

export const repairsLoadDataFunc = (args: APIListValuesRequest) =>
  listRepairsValues(args).then(transformListValuesAPIResponseFunc);

export const failureModesLoadDataFunc = (args: APIListValuesRequest) =>
  listFailureModesValues(args).then(transformListValuesAPIResponseFunc);

export const signalEventsOccurrencesLoadDataFunc = (
  args: APIListValuesRequest
) =>
  listSignalEventsOccurrencesValues(args).then(
    transformListValuesAPIResponseFunc
  );

export const issuesLoadDataFunc = (args: APIListValuesRequest) => {
  return listIssuesValues(args).then(transformListValuesAPIResponseFunc);
};

export const suggestedIssuesLoadDataFunc = (args: APIListValuesRequest) => {
  return listSuggestedIssuesValues(args).then(
    transformListValuesAPIResponseFunc
  );
};

export const servicePlansLoadDataFunc = (args: APIListValuesRequest) => {
  return listServicePlansValues(args).then(transformListValuesAPIResponseFunc);
};

export const serviceRecommendationsLoadDataFunc = (
  args: APIListValuesRequest
) => {
  return listServiceRecommendationsValues(args).then(
    transformListValuesAPIResponseFunc
  );
};

export const servicePlansCollectionsLoadDataFunc = ({
  filter,
}: APIListValuesRequest) => {
  return listServicePlansValues({ fieldName: "collectionID", filter })
    .then(({ data: { distinctValues } }) => {
      return listVehicleCollections({
        filter: getFiltersQuery(
          filterStateToFilterGroupState({
            ID: {
              values: excludeNulls(distinctValues),
              operator: FilterOperator.IN,
            },
          })
        ),
      });
    })
    .then(transformCollectionAPIResponseFunc);
};

export const vehicleCollectionsLoadDataFunc = ({
  limit,
}: APIListValuesRequest): Promise<SelectOption[]> => {
  return listVehicleCollections({ limit }).then(
    transformCollectionAPIResponseFunc
  );
};

export const vehicleCollectionsValuesLoadDataFunc = (
  args: APIListValuesRequest
) => {
  return listVehicleCollectionsValues(args).then(
    transformListValuesAPIResponseFunc
  );
};

export const failureModesV0LoadDataFunc = (): Promise<SelectOption[]> => {
  return listFailureModes({}).then(transformFailureModeAPIResponseFunc);
};

export const transportCategoriesLoadDataFunc = (): Promise<SelectOption[]> => {
  return listTransportCategories().then(
    transformTransportCategoryAPIResponseFunc
  );
};

export const groupsLoadDataFunc = (
  args: APIListValuesRequest
): Promise<SelectOption[]> => {
  return listGroupsValues(args).then(transformListValuesAPIResponseFunc);
};

export const loadGroupsListFunc = async ({
  fieldName,
  like,
}: APIListValuesRequest): Promise<SelectOption[]> =>
  listGroups({
    filter:
      like &&
      getFiltersQuery(
        filterStateToFilterGroupState({
          [fieldName]: {
            operator: FilterOperator.STARTS_WITH,
            values: [like],
          },
        } as FilterState)
      ),
  }).then(({ data }) =>
    data.map(({ ID, name }) => {
      return {
        id: ID,
        value: name,
      } as SelectOption;
    })
  );

export const calculatedAttributesLoadDataFunc = (
  args: APIListValuesRequest
): Promise<SelectOption[]> =>
  listCalculatedAttributesValues(args).then(transformListValuesAPIResponseFunc);

export const suggestedIssueGroupNameLoadDataFunc = ({
  filter,
}: APIListValuesRequest) => {
  return listSuggestedIssuesValues({
    fieldName: "assignedGroupID",
    filter,
  })
    .then(({ data: { distinctValues } }) => {
      return listGroups({
        filter: getFiltersQuery(
          filterStateToFilterGroupState({
            ID: {
              values: excludeNulls(distinctValues),
              operator: FilterOperator.IN,
            },
          })
        ),
      });
    })
    .then(transformGroupAPIResponseFunc);
};

export const issueSeverityLoadDataFunc = (_: APIListValuesRequest) => {
  return listOrderedValuesValues({
    fieldName: "value",
    valueType: "issueSeverity",
  }).then(
    ({ data: { distinctValues } }) =>
      distinctValues.map((value) => ({
        id: value?.toString(),
        value: value?.toString(),
      })) as SelectOption[]
  );
};

export const issuePromotedFromLoadDataFunc = (_: APIListValuesRequest) => {
  return listIssues({
    filter: "promotedFromID=isn:null",
  }).then(
    ({ data }) =>
      data.map(({ ID, name }) => ({
        id: ID,
        value: name,
      })) as SelectOption[]
  );
};

export const issueStatusLoadDataFunc = (_: APIListValuesRequest) => {
  return listOrderedValuesValues({
    fieldName: "value",
    valueType: "issueStatus",
  }).then(
    ({ data: { distinctValues } }) =>
      distinctValues.map((value) => ({
        id: value?.toString(),
        value: value?.toString(),
      })) as SelectOption[]
  );
};

export const suggestedIssueStatusLoadDataFunc = (_: APIListValuesRequest) => {
  return listOrderedValuesValues({
    fieldName: "value",
    valueType: "suggestedIssueStatus",
  }).then(
    ({ data: { distinctValues } }) =>
      distinctValues.map((value) => ({
        id: value?.toString(),
        value: value?.toString(),
      })) as SelectOption[]
  );
};

export const issueGroupNameLoadDataFunc = ({
  filter,
}: APIListValuesRequest) => {
  return listIssuesValues({
    fieldName: "assignedGroupID",
    filter,
  })
    .then(({ data: { distinctValues } }) => {
      return listGroups({
        filter: getFiltersQuery(
          filterStateToFilterGroupState({
            ID: {
              values: excludeNulls(distinctValues),
              operator: FilterOperator.IN,
            },
          })
        ),
      });
    })
    .then(transformGroupAPIResponseFunc);
};

export const failureModeLoadDataFunc = (
  args: APIListValuesRequest
): Promise<SelectOption[]> => {
  return listFailureModePredictionsValues(args).then(
    transformListValuesAPIResponseFunc
  );
};

export const signalEventsLoadDataFunc = (
  args: APIListValuesRequest
): Promise<SelectOption[]> => {
  return listSignalEventsValues(args).then(transformListValuesAPIResponseFunc);
};

export const v0_failureModeEventTypeLoadDataFunc = (
  args: v0_APIListValuesFailureModeEventRequest
): Promise<SelectOption[]> => {
  return v0_listFailureModeEventsValues(args).then(
    transformListValuesAPIResponseFunc
  );
};

export const serviceRecordsLoadDataFunc = (
  args: APIListValuesRequest
): Promise<SelectOption[]> => {
  return listServiceRecordsValues(args).then(
    transformListValuesAPIResponseFunc
  );
};

export const signalEventsValuesLoadDataFunc = (
  args: APIListValuesRequest
): Promise<SelectOption[]> => {
  return listSignalEventsValues(args).then(transformListValuesAPIResponseFunc);
};

export const dealersLoadDataFunc = (args: APIListValuesRequest) => {
  return listDealerValues(args).then(transformListValuesAPIResponseFunc);
};

export const alertDefinitionsLoadDataFunc = (args: APIListValuesRequest) => {
  return listAlertDefinitionValues(args).then(
    transformListValuesAPIResponseFunc
  );
};

export const customSignalEventDefinitionsLoadDataFunc = (
  args: APIListValuesRequest
) => {
  return listCustomSignalEventDefinitionValues(args).then(
    transformListValuesAPIResponseFunc
  );
};

export const failureModeEventLoadDataFunc = (
  args: APIListValuesFailureModeEventRequest
): Promise<SelectOption[]> => {
  return listFailureModeEventsValues(args).then(
    transformListValuesAPIResponseFunc
  );
};

export const failureModeRiskModelPredictionLoadDataFunc = (
  args: APIListValuesFailureModeEventRequest
): Promise<SelectOption[]> =>
  listFailureModeRiskModelPredictionsValues(args).then(
    transformListValuesAPIResponseFunc
  );

const transformListValuesAPIResponseFunc = ({
  data: { distinctValues },
}: APISuccessResponse<APIListValuesResponse>): SelectOption[] => {
  if (!distinctValues) {
    return [];
  }
  return excludeNulls(distinctValues).map((v) => ({ id: v, value: v }));
};

const transformCollectionAPIResponseFunc = ({
  data,
}: APISuccessResponse<Collection[]>): SelectOption[] => {
  if (!data) {
    return [];
  }
  return data.map(({ ID, name }) => ({ id: ID, value: name }));
};

const transformGroupAPIResponseFunc = ({
  data,
}: APISuccessResponse<Group[]>): SelectOption[] => {
  if (!data) {
    return [];
  }
  return data.map(({ ID, name }) => ({ id: ID, value: name }));
};

const transformFailureModeAPIResponseFunc = ({
  data,
}: APISuccessResponse<FailureMode[]>): SelectOption[] => {
  if (!data) {
    return [];
  }
  return data.map(({ ID, name }) => ({ id: ID, value: name }));
};

const transformTransportCategoryAPIResponseFunc = ({
  data,
}: APISuccessResponse<VehicleGenericCategory[]>): SelectOption[] => {
  if (!data) {
    return [];
  }
  return data.map(({ ID, name }) => ({ id: ID, value: name }));
};

export const transformInitialSelectedCollection = (
  initialSelected: SelectOption[]
): Promise<SelectOption[]> => {
  return getCollection({ id: initialSelected[0].id as string }).then(
    ({ data }) => {
      return [{ id: data.ID, value: data.name }];
    }
  );
};

export const transformInitialSelectedGroup = async (
  initialSelected: SelectOption[]
): Promise<SelectOption[]> => {
  const { data: groups } = await listGroups({});
  return Promise.all(
    initialSelected.map(async ({ id }) => {
      return {
        id,
        value: groups.find(({ ID }) => ID === id)?.name || "",
      };
    })
  );
};

export const transformInitialSelectedFailureMode = async (
  initialSelected: SelectOption[]
): Promise<SelectOption[]> => {
  const { data: failureModes } = await listFailureModes({});

  return Promise.all(
    initialSelected.map(async ({ id }) => {
      return {
        id,
        value: failureModes.find((fm) => fm.ID === id)?.name || "",
      };
    })
  );
};

export const transformInitialSelectedTransportCategory = async (
  initialSelected: SelectOption[]
): Promise<SelectOption[]> => {
  const { data: failureModes } = await listTransportCategories();

  return Promise.all(
    initialSelected.map(async ({ id }) => {
      return {
        id,
        value: failureModes.find((tc) => tc.ID === id)?.name || "",
      };
    })
  );
};

export const loadSensorsFunc = async (
  args: APIListValuesRequest
): Promise<SelectOption[]> =>
  getSensors({
    limit: SENSORS_LIMIT,
    ...args,
  }).then(({ data }) =>
    data.map(({ ID, displayName }) => {
      return {
        id: ID,
        value: displayName,
      } as SelectOption;
    })
  );

export const loadSensorReadingsValuesFunc = async (
  args: APIListValuesRequest
): Promise<SelectOption[]> =>
  listSensorsReadingValues(args).then(({ data: { distinctValues } }) =>
    distinctValues.map((value) => {
      return {
        id: value,
        value,
      } as SelectOption;
    })
  );

export const transformInitialSelectedIssues = async (
  initialSelected: SelectOption[]
): Promise<SelectOption[]> => {
  const { data: issues } = await listIssues({
    filter: getFiltersQuery(
      filterStateToFilterGroupState({
        ID: {
          operator: FilterOperator.IN,
          values: initialSelected.map(({ id }) => id.toString()),
        },
      })
    ),
  });

  return Promise.all(
    initialSelected.map(async ({ id }) => {
      return {
        id,
        value: issues.find(({ ID }) => ID === id)?.name || "",
      };
    })
  );
};

export const transformInitialSelectedSensors = async (
  initialSelected: SelectOption[]
): Promise<SelectOption[]> => {
  const { data: sensors } = await getSensors({
    filter: getFiltersQuery(
      filterStateToFilterGroupState({
        ID: {
          operator: FilterOperator.IN,
          values: initialSelected.map(({ id }) => id.toString()),
        },
      })
    ),
  });

  return Promise.all(
    initialSelected.map(async ({ id }) => {
      return {
        id,
        value: sensors.find(({ ID }) => ID === id)?.displayName || "",
      };
    })
  );
};
