import { useContext } from "react";

import "shared/filterDefinitions";

import { RiskModelPredictionAttributesContext } from "shared/contexts/RiskModelPredictionAttributesContextWrapper";

import { FilterSchemaItem } from "features/ui/Filters/types";
import { SchemaEntry } from "features/ui/Table";

import { SchemaRelations, UseSchema } from "./types";
import {
  getAttributeMap,
  getDisplayLabelFunction,
  getFilterFunction,
  getSchemaEntryFunction,
  getSchemaForAccessorFunction,
  getSchemaFromAttributes,
} from "./utils";
import useVehiclesSchema from "./vehiclesSchema";

export const useRiskModelPredictionsSchema = (
  filterGenerator: (item: FilterSchemaItem) => FilterSchemaItem,
  accessorsToHide: string[] = [],
  vehicleAccessorsToHide: string[] = []
): UseSchema => {
  const attributes = useContext(RiskModelPredictionAttributesContext);

  const vehiclesSchema = useVehiclesSchema(vehicleAccessorsToHide);

  const attributeMap = getAttributeMap(attributes);

  const getDisplayLabel = getDisplayLabelFunction(attributeMap);

  const getFilter = getFilterFunction(filterGenerator, attributeMap);

  const getSchemaEntry = getSchemaEntryFunction(getFilter, attributeMap);

  const relations: SchemaRelations = {
    "/v1/vehicles": vehiclesSchema,
  };

  const overrides = {
    riskStatus: {
      filterOverride: {
        disableArbitraryText: true,
      },
    },
    riskLabel: {
      filterOverride: {
        disableArbitraryText: true,
      },
    },
  };

  const schema: SchemaEntry[] = getSchemaFromAttributes(
    attributes,
    getSchemaEntry,
    overrides,
    relations,
    accessorsToHide,
    true
  );

  const getSchemaForAccessor = getSchemaForAccessorFunction(schema);

  return {
    schema,
    attributes,
    getDisplayLabel,
    attributeMap,
    whitelist: Object.keys(attributeMap || {}),
    getSchemaEntry,
    getSchemaForAccessor,
  };
};
