import loadPrompt from "duck/graph/nodes/loadPrompt";
import { promptNames } from "duck/graph/nodes/types";
import {
  createStrictToolCallingAgent,
  invokeAgentNode,
  NodeType,
} from "duck/graph/nodes/utils";
import getTheRespondToUserTool from "duck/graph/tools/getRespondToUserTool";
import {
  routeToClaimAnalyticsToolDef,
  routeToRagToolDef,
  routeToRejectClarifyToolDef,
  routeToSignalEventAnalyticsToolDef,
} from "duck/graph/tools/routingTools";
import { DuckGraphParams } from "duck/graph/types";
import { getLLM, NodeNames } from "duck/graph/utils";

const getNode = async (params: DuckGraphParams): Promise<NodeType> => {
  const prompt = await loadPrompt(promptNames.SUPERVISOR_AGENT);
  const llm = getLLM();
  const respondToUserTool = getTheRespondToUserTool(
    params.uiHandlers.setAgentResponse
  );
  const tools = [
    respondToUserTool,
    routeToClaimAnalyticsToolDef,
    routeToRagToolDef,
    routeToRejectClarifyToolDef,
    routeToSignalEventAnalyticsToolDef,
  ];
  // disable parallel tool calls (we currently don't want to route to multiple agents)
  const agent = createStrictToolCallingAgent(llm, tools, prompt, false);

  return invokeAgentNode(agent, undefined, NodeNames.SUPERVISOR);
};

export default getNode;
