import { validateFilter } from "duck/graph/nodes/SignalEventAnalyticsAgent/utils";
import { PageHandler } from "duck/graph/PageHandler";
import { z } from "zod";
import { DynamicStructuredTool, tool } from "@langchain/core/tools";

import { SIGNAL_EVENTS_PAGE_KEY } from "pages/SignalEventsAnalytics/constants";

import { getFiltersKey } from "features/ui/Filters/utils";

const updateSignalEventOccurrencesFilterSchema = z.object({
  signalEventOccurrencesFilter: z
    .string()
    .optional()
    .describe(
      "Filter string for Signal Event Occurrences. Use empty string to clear/reset filter. Call retrieveSignalEventOccurrencesAttributes to get the list of available attributes."
    ),
});

type UpdatedSignalEventOccurrencesFilter = z.infer<
  typeof updateSignalEventOccurrencesFilterSchema
>;

const getUpdateSignalEventOccurrencesFilterTool = (
  pageHandler: PageHandler
): DynamicStructuredTool => {
  const updateSignalEventsFilter = async ({
    signalEventOccurrencesFilter,
  }: UpdatedSignalEventOccurrencesFilter): Promise<string> => {
    console.debug("[SEA]: Updating Signal Event Occurrences filters", {
      signalEventOccurrencesFilter,
    });

    const filterQueryString = signalEventOccurrencesFilter || "";

    await validateFilter({ signalEventOccurrencesFilter: filterQueryString });

    pageHandler.updateFilter(
      filterQueryString,
      getFiltersKey(SIGNAL_EVENTS_PAGE_KEY)
    );
    return "Queued update signal event occurrences filter";
  };

  return tool(updateSignalEventsFilter, {
    name: "queueUpdateSignalEventOccurrencesFilter",
    description:
      "Call this tool to queue an action that updates the Signal Event " +
      "Occurrences filters given a text query. Only call this for Signal Event Occurrences attributes.",
    schema: updateSignalEventOccurrencesFilterSchema,
  });
};

export default getUpdateSignalEventOccurrencesFilterTool;
