import {
  alertDefinitionsLoadDataFunc,
  calculatedAttributesLoadDataFunc,
  claimsLoadDataFunc,
  customRecordsLoadDataFunc,
  customSignalEventDefinitionsLoadDataFunc,
  dealersLoadDataFunc,
  failureModeEventLoadDataFunc,
  failureModeLoadDataFunc,
  failureModeRiskModelPredictionLoadDataFunc,
  failureModesLoadDataFunc,
  failureModesV0LoadDataFunc,
  groupsLoadDataFunc,
  inspectionsLoadDataFunc,
  issueGroupNameLoadDataFunc,
  issuePromotedFromLoadDataFunc,
  issueSeverityLoadDataFunc,
  issuesLoadDataFunc,
  issueStatusLoadDataFunc,
  loadSensorsFunc,
  repairsLoadDataFunc,
  servicePlansCollectionsLoadDataFunc,
  servicePlansLoadDataFunc,
  serviceRecommendationsLoadDataFunc,
  serviceRecordsLoadDataFunc,
  signalEventsOccurrencesLoadDataFunc,
  signalEventsValuesLoadDataFunc,
  suggestedIssueGroupNameLoadDataFunc,
  suggestedIssuesLoadDataFunc,
  suggestedIssueStatusLoadDataFunc,
  transformInitialSelectedCollection,
  transformInitialSelectedFailureMode,
  transformInitialSelectedGroup,
  transformInitialSelectedIssues,
  transformInitialSelectedSensors,
  transformInitialSelectedTransportCategory,
  transportCategoriesLoadDataFunc,
  v0_failureModeEventTypeLoadDataFunc,
  vehicleCollectionsLoadDataFunc,
  vehicleCollectionsValuesLoadDataFunc,
  vehicleECUsLoadDataFunc,
  vehicleLoadDataFunc,
  vehicleTagsLoadDataFunc,
} from "shared/api/filter.utils";

import CollectionDescriptionTooltip from "pages/Collections/CollectionDescriptionTooltip";
import { getPopulationKeyValuePairs } from "pages/Issues/utils";

import { FilterSchemaItem } from "features/ui/Filters/types";
import { DataType } from "features/ui/Table/TableBodyCell";

import { APIListValuesRequest } from "./api/api";

export const VEHICLE_COLLECTION_FILTER_KEY = "collection";

export const VEHICLES_GENERIC_FILTER = ({
  filterType = "string",
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => {
  return {
    filterType,
    loadOptionsFunc: vehicleLoadDataFunc,
    ...otherProps,
  };
};

export const VEHICLE_TAGS_GENERIC_FILTER = ({
  filterType = "string",
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => {
  return {
    filterType,
    loadOptionsFunc: vehicleTagsLoadDataFunc,
    ...otherProps,
  };
};

export const GROUPS_GENERIC_FILTER = ({
  filterType = "string",
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => {
  return {
    filterType,
    loadOptionsFunc: groupsLoadDataFunc,
    ...otherProps,
  };
};

export const SUGGESTED_ISSUE_GROUP_NAME_FILTER = ({
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => {
  return {
    ...otherProps,
    fieldName: "assignedGroupID",
    loadOptionsFunc: suggestedIssueGroupNameLoadDataFunc,
    transformInitialSelectedFunc: transformInitialSelectedGroup,
  };
};

export const SUGGESTED_ISSUE_ISSUE_NAME_FILTER = ({
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => {
  return {
    ...otherProps,
    loadOptionsFunc: issuePromotedFromLoadDataFunc,
    transformInitialSelectedFunc: transformInitialSelectedIssues,
  };
};

export const SUGGESTED_ISSUE_STATUS_FILTER = ({
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => {
  return {
    ...otherProps,
    fieldName: "statusObj.value",
    loadOptionsFunc: suggestedIssueStatusLoadDataFunc,
  };
};

export const ISSUE_STATUS_FILTER = ({
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => {
  return {
    ...otherProps,
    fieldName: "statusObj.value",
    loadOptionsFunc: issueStatusLoadDataFunc,
  };
};

export const ISSUE_SEVERITY_FILTER = ({
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => {
  return {
    ...otherProps,
    fieldName: "severityObj.value",
    loadOptionsFunc: issueSeverityLoadDataFunc,
  };
};

export const ISSUE_GROUP_NAME_FILTER = ({
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => {
  return {
    ...otherProps,
    fieldName: "assignedGroupID",
    loadOptionsFunc: issueGroupNameLoadDataFunc,
    transformInitialSelectedFunc: transformInitialSelectedGroup,
  };
};

export const SERVICE_PLANS_GENERIC_FILTER = ({
  label,
  fieldName,
  search = false,
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => {
  return {
    label,
    fieldName,
    search,
    loadOptionsFunc: servicePlansLoadDataFunc,
    ...otherProps,
  };
};

export const SERVICE_RECOMMENDATIONS_GENERIC_FILTER = ({
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => {
  return {
    loadOptionsFunc: serviceRecommendationsLoadDataFunc,
    ...otherProps,
  };
};

export const CLAIMS_GENERIC_FILTER = (
  props: FilterSchemaItem
): FilterSchemaItem => {
  return {
    ...props,
    loadOptionsFunc: claimsLoadDataFunc,
  };
};

export const INSPECTIONS_GENERIC_FILTER = (
  props: FilterSchemaItem
): FilterSchemaItem => {
  return {
    ...props,
    loadOptionsFunc: inspectionsLoadDataFunc,
  };
};

export const REPAIRS_GENERIC_FILTER = (
  props: FilterSchemaItem
): FilterSchemaItem => {
  return {
    ...props,
    loadOptionsFunc: repairsLoadDataFunc,
  };
};

export const CUSTOM_RECORDS_GENERIC_FILTER = (
  props: FilterSchemaItem
): FilterSchemaItem => {
  return {
    ...props,
    loadOptionsFunc: customRecordsLoadDataFunc,
  };
};

export const FAILURE_MODES_GENERIC_FILTER = (
  props: FilterSchemaItem
): FilterSchemaItem => {
  return {
    ...props,
    loadOptionsFunc: failureModesLoadDataFunc,
  };
};

export const VEHICLES_ECUS_GENERIC_FILTER = ({
  filterType = "string",
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => {
  return {
    filterType,
    loadOptionsFunc: vehicleECUsLoadDataFunc,
    ...otherProps,
    disableFiltering: true,
  };
};

export const SIGNAL_EVENT_OCCURRENCES_GENERIC_FILTER = (
  props: FilterSchemaItem
): FilterSchemaItem => {
  return {
    ...props,
    loadOptionsFunc: signalEventsOccurrencesLoadDataFunc,
    disableFiltering: true,
  };
};

export const SENSOR_READINGS_GENERIC_FILTER = (
  props: FilterSchemaItem
): FilterSchemaItem => {
  return {
    ...props,
    loadOptionsFunc: signalEventsOccurrencesLoadDataFunc,
    disableFiltering: true,
  };
};

export const SENSOR_ID_FILTER = (props: FilterSchemaItem): FilterSchemaItem => {
  return {
    ...props,
    loadOptionsFunc: loadSensorsFunc,
    transformInitialSelectedFunc: transformInitialSelectedSensors,
    disableFiltering: true,
  };
};

export const ISSUES_GENERIC_FILTER = ({
  filterType = "string",
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => {
  return {
    filterType,
    loadOptionsFunc: issuesLoadDataFunc,
    ...otherProps,
  };
};

export const DEALERS_GENERIC_FILTER = ({
  filterType = "string",
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => {
  return {
    filterType,
    loadOptionsFunc: dealersLoadDataFunc,
    ...otherProps,
  };
};

export const SUGGESTED_ISSUE_GENERIC_FILTER = ({
  filterType = "string",
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => {
  return {
    filterType,
    loadOptionsFunc: suggestedIssuesLoadDataFunc,
    ...otherProps,
  };
};

export const ISSUE_CLAIMS_GENERIC_FILTER = ({
  filterType = "string",
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => {
  return {
    filterType,
    ...otherProps,
  };
};

export const ALERT_DEFINITIONS_GENERIC_FILTER = ({
  search = false,
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => {
  return {
    search,
    loadOptionsFunc: alertDefinitionsLoadDataFunc,
    ...otherProps,
  };
};

export const CUSTOM_SIGNAL_EVENT_DEFINITIONS_GENERIC_FILTER = ({
  search = false,
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => {
  return {
    search,
    loadOptionsFunc: customSignalEventDefinitionsLoadDataFunc,
    ...otherProps,
  };
};

export const SERVICE_PLANS_COLLECTION_FILTER: FilterSchemaItem = {
  label: "Vehicle Collection",
  fieldName: "collectionID",
  search: true,
  loadDataOnOpen: false,
  loadOptionsFunc: servicePlansCollectionsLoadDataFunc,
  transformInitialSelectedFunc: transformInitialSelectedCollection,
  multiple: true,
  description: <CollectionDescriptionTooltip />,
};

export const VEHICLE_COLLECTIONS_CREATED_BY_FILTER: FilterSchemaItem = {
  label: "Created By",
  fieldName: "createdBy",
  loadOptionsFunc: vehicleCollectionsValuesLoadDataFunc,
};

export const VEHICLES_COLLECTION_FILTER: FilterSchemaItem = {
  label: "Vehicle Collection",
  fieldName: VEHICLE_COLLECTION_FILTER_KEY,
  search: true,
  loadDataOnOpen: true,
  loadOptionsFunc: vehicleCollectionsLoadDataFunc,
  transformInitialSelectedFunc: transformInitialSelectedCollection,
  multiple: false,
  description: <CollectionDescriptionTooltip />,
};

export const FAILURE_MODE_FILTER: FilterSchemaItem = {
  label: "Failure Mode",
  fieldName: "failureModeID",
  loadOptionsFunc: failureModesV0LoadDataFunc,
  transformInitialSelectedFunc: transformInitialSelectedFailureMode,
  disableFiltering: true,
};

export const TRANSPORT_CATEGORY_FILTER: FilterSchemaItem = {
  label: "Transport Category",
  fieldName: "transportCategoryID",
  loadOptionsFunc: transportCategoriesLoadDataFunc,
  transformInitialSelectedFunc: transformInitialSelectedTransportCategory,
  disableFiltering: true,
};

export const RISK_FILTER: FilterSchemaItem = {
  label: "Risk Group",
  fieldName: "riskGroup",
  loadOptionsFunc: failureModeLoadDataFunc,
};

export const FAILURE_MODE_PREDICTIONS_VIN_FILTER: FilterSchemaItem = {
  label: "VIN",
  fieldName: "VIN",
  loadOptionsFunc: failureModeLoadDataFunc,
};

export const RISK_STATUS_FILTER: FilterSchemaItem = {
  label: "Risk Status",
  fieldName: "riskStatus",
  loadOptionsFunc: failureModeLoadDataFunc,
};

export const FAILURE_MODE_EVENT_TYPE_FILTER = (
  failureModeId: string
): FilterSchemaItem => ({
  label: "Event type",
  fieldName: "eventType",
  loadOptionsFunc: (args: APIListValuesRequest) =>
    v0_failureModeEventTypeLoadDataFunc({ failureModeId, ...args }),
});

export const FAILURE_MODE_EVENT_VIN_FILTER = (
  failureModeId: string
): FilterSchemaItem => ({
  label: "VIN",
  fieldName: "VIN",
  loadOptionsFunc: (args: APIListValuesRequest) =>
    v0_failureModeEventTypeLoadDataFunc({ failureModeId, ...args }),
});

export const EVENT_TYPE_FILTER = (filter?: string): FilterSchemaItem => ({
  label: "Type",
  fieldName: "type",
  loadOptionsFunc: (args: APIListValuesRequest) =>
    serviceRecordsLoadDataFunc({ ...args, filter }),
});

export const SERVICE_RECORD_GENERIC_FILTER = ({
  filterType = "string",
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => {
  return {
    filterType,
    ...otherProps,
    loadOptionsFunc: serviceRecordsLoadDataFunc,
  };
};

export const DATE_FILTER_GENERIC = ({
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => {
  return {
    ...otherProps,
    filterType: "date",
    disableSelectFilters: true,
  };
};

export const SIGNAL_EVENTS_GENERIC_FILTER = ({
  ...props
}: FilterSchemaItem): FilterSchemaItem => {
  return {
    loadOptionsFunc: signalEventsValuesLoadDataFunc,
    ...props,
  };
};

export const ISSUE_SIGNAL_EVENTS_FILTER = ({
  filterType = "string",
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => {
  return {
    filterType,
    ...otherProps,
  };
};

export const ISSUE_POPULATIONS_FILTER: FilterSchemaItem = {
  filterType: "string",
  loadOptionsFunc: () => Promise.all(getPopulationKeyValuePairs()),
  label: "Population(s)",
  fieldName: "populations",
};

export const GENERIC_FILTER_WITHOUT_SELECT = ({
  ...props
}: FilterSchemaItem): FilterSchemaItem => {
  return {
    ...props,
    disableSelectFilters: true,
    disableFiltering: true,
  };
};

export const CALCULATED_ATTRIBUTES_GENERIC_FILTER = ({
  filterType = "string",
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => {
  return {
    filterType,
    loadOptionsFunc: calculatedAttributesLoadDataFunc,
    ...otherProps,
  };
};

export const CREATED_AT_FILTER: FilterSchemaItem = {
  label: "Created",
  fieldName: "createdAt",
  filterType: "date",
  filterDataType: DataType.DATE_WITH_TIME_UTC,
  disableSelectFilters: true,
};

export const UPDATED_AT_FILTER: FilterSchemaItem = {
  label: "Update",
  fieldName: "updatedAt",
  filterType: "date",
  filterDataType: DataType.DATE_WITH_TIME_UTC,
  disableSelectFilters: true,
};

export const FAILURE_MODE_EVENTS_GENERIC_FILTER = (
  id: string,
  props: FilterSchemaItem
): FilterSchemaItem => ({
  ...props,
  loadOptionsFunc: (args: APIListValuesRequest) =>
    failureModeEventLoadDataFunc({ id, ...args }),
});

export const FAILURE_MODE_RISK_MODEL_PREDICTIONS_GENERIC_FILTER = (
  id: string,
  props: FilterSchemaItem
): FilterSchemaItem => ({
  ...props,
  loadOptionsFunc: (args: APIListValuesRequest) =>
    failureModeRiskModelPredictionLoadDataFunc({ id, ...args }),
});
